import React, { createContext, useContext, useState } from "react";

// Creating the context with default values.
const SearchResultContext = createContext({
    result: null,
    isLoading: false,
    setLoading: () => {}
});

export const SearchResultProvider = ({ children }) => {
    const [result, setResult] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const handleSetResult = async (newResult) => {
        try {
            setLoading(true);
           await setResult(newResult);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <SearchResultContext.Provider value={{ result, handleSetResult, isLoading }}>
            {children}
        </SearchResultContext.Provider>
    );
};

function useSearch() {
    const context = useContext(SearchResultContext);

    if (context === undefined) {
        throw new Error("useSearch must be used within a SearchResultProvider");
    }

    return context;
}

export { SearchResultContext, useSearch };
