import { enUS, ruRU, srRS, huHU, skSK } from "@mui/material/locale";
import { translate } from "./i18n";

export const allLanguages = [
  {
    label: translate("english"),
    value: "en",
    systemValue: enUS,
    currency: "usd",
    icon: "/assets/icons/flags/ic_flag_en.svg"
  },
  {
    label: translate("serbian"),
    value: "sr",
    currency: "RSD",
    systemValue: srRS,
    icon: "/assets/icons/flags/ic_flag_sr.svg"
  },
  {
    label: translate("serbianCir"),
    value: "cir",
    currency: "RSD",
    systemValue: srRS,
    icon: "/assets/icons/flags/ic_flag_sr.svg"
  },
  {
    label: translate("russian"),
    value: "ru",
    currency: "RUB",
    systemValue: ruRU,
    icon: "/assets/icons/flags/ic_flag_ru.svg"
  },
  {
    label: translate("hungarian"),
    value: "hu",
    currency: "HUF",
    systemValue: huHU,
    icon: "/assets/icons/flags/ic_flag_hu.svg"
  },
  {
    label: translate("slovakia"),
    value: "sk",
    currency: "EUR",
    systemValue: skSK,
    icon: "/assets/icons/flags/ic_flag_sk.svg"
  }
];

export const defaultLang = allLanguages[1]; // Serbian
