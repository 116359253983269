import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getAllDiscountsApi} from "../../api/be/discounts";

export const fetchAllDiscountsType = createAsyncThunk('fetchTicketType', async () => {
    return await getAllDiscountsApi()
})

const initialState = {
    discountList: [],
    allDiscountList: [],
    loadingDiscounts: false,
    searchParam: ""
}

export const discountSlice = createSlice({
    name: 'discounts',
    initialState,
    reducers: {
        setSearchParam: (state, action) => {
            state.searchParam = action.payload
        }
    },
    extraReducers: {
        [fetchAllDiscountsType.pending]: (state) => {
            state.loadingDiscounts = true;
        },
        [fetchAllDiscountsType.fulfilled]: (state, {payload}) => {
            state.discountList = payload.data.content.filter(i => i.active);
            state.allDiscountList = payload.data.content;
            state.loadingDiscounts = false;
        },
        [fetchAllDiscountsType.rejected]: (state) => {
            state.loadingDiscounts = false;
        }
    }
});

export const discountReducer = discountSlice.reducer
