import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getClientInfo} from "../../api/node/client";


export const getClientDetails = createAsyncThunk('getClientDetails', async (tin) => {
    const response = await getClientInfo(tin);
    return response.data;
})

const initialState = {
    isSuccessAdded: false,
    loadingInfo: false,
    clientDetails: undefined,
    clientDetailsError: undefined,
}

const customersSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        resetIsSuccessAdded: (state) => {
            state.isSuccessAdded = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getClientDetails.pending, (state) => {
                state.loadingInfo = true;
            })
            .addCase(getClientDetails.fulfilled, (state, {payload}) => {
                state.loadingInfo = false;
                state.clientDetailsError = undefined;
                state.clientDetails = payload;
            })
            .addCase(getClientDetails.rejected, (state, action) => {
                state.clientDetailsError = action.error;
                state.loadingInfo = false;
            });
    },
});

export default customersSlice.reducer;