const termsCirText = `Пажња: молимо вас да пажљиво прочитате услове коришћења пре употребе интернет сајта. Коришћењем нашег интернет сајта потврђујете да сте прочитали, разумели и прихватили ове услове коришћења (у даљем тексту: Услови). Јавно градско саобраћајно предузеће „Нови Сад“, Нови Сад задржава право да у било ком тренутку измени Услове уношењем измена у овај текст. Предлажемо да с времена на време посетите ову интернет страницу и проверите да ли су се Услови можда променили од ваше последње посете.
Аутобуска станица „Нови Сад“ послује у склопу Јавног градског саобраћајног предузећа „Нови Сад“, Нови Сад чији је Оснивач Скупштина Града Новог Сада. Аутобуска станица „Нови Сад“ пружа станичне услуге превозницима и путницима од децембра 1967. године а данас своју делатност пружања станичних услуга врши у складу са одредбама важећег Закона о превозу путника у друмском саобраћају и Општим условима пословања аутобуских станица. У вези са горе наведеним Министарство инфраструктуре, грађевине и саобраћаја је Јавном градском саобраћајном предузећу „Нови Сад“, Нови Сад издало лиценцу за пружање станичних услуга. У складу са важећим Законом о превозу путника у друмском саобраћају и важећим Општим условима пословања аутобуских станица дужни смо да Вас обавестимо о следећем:
- Да Аутобуска станица „Нови Сад“ врши резервацију и продају карата у међумесном и међународном превозу путника у име и за рачун превозника. Возна карта је доказ да је закључен Уговор о превозу између превозника и путника;
- Путник је дужан да поседује документ којим се потвђује одабрани попуст (индекс за студенте, здравствену књижицу за децу, чек од пензије…).
- Да делатност пружања станичних услуга обухвата: давање обавештења која се односе на време полазака и долазака аутобуса, цену превоза, назив превозника и сл, продају возних карата и издавање других превозних исправа у вези са превозом; укрцавање и искрцавање путника; смештај и чување пртљага и пријем и отпрему аутобуса;
- Да су корисници станичних услуга превозници и путници;
- Да је пружалац услуга дужан да возне карте и друге превозне карте продаје и издаје у складу са регистрованим односно одобреним редом вожње и важећим ценовником.
Пружалац стничних услуга је:
Јавно градско саобраћајно предузеће „Нови Сад“, Нови Сад
Футошки пут број 46
21137 Нови Сад
МБ: 08041822
ПИБ: 100277615
Аутобуска станица „Нови Сад“ се налази на адреси Бул Јаше Томића број 6 у Новом Саду.

Садржаји објављени на интернет сајту власништво су Јавног градског саобраћајног предузећа „Нови Сад“, Нови Сад и могу се употребљавати само у некомерцијалне сврхе, при чему се морају поштовати и сва наведена упозорења о ауторским правима. Није дозвољено мењати садржај овог сајта нити апликације ни на који начин, нити га репродуковати или јавно приказивати, изводити, дистрибуирати или на други начин користити у јавне или комерцијалне сврхе. Уколико желите да користите садржаје објављене на овом интернет сајту или апликацији у друге сврхе од овде наведених потребно је да нас контактирате ради добијања дозволе.
Када користите наш интернет сајт није дозвољено да:
- Ометате или онемогућавате било које функције повезане са сигурношћу на сајту или функције које спречавају или ограничавају употребу или копирање доступног садржаја
- Објављујете или преносите на интернет сајт или на апликацију сваки материјал који је претећи, клеветнички, непристојан, увредљив, порнографски или злостављачки. Такође материјал којим можете иницирати расну мржњу, узрокујете узнемиравање или било које непријатности услед кршења приватности, поверења одређеним дискриминационим, претећим или изазивачким коментарима
- Дајете нетачне информације које се тичу личних података у вашем профилу
- Користите туђе личне податке без дозволе те особе или лажно представљате да иступате у име трећег лица, фирме или организације
- Бавите се било којим незаконитим или противправним понашањем
- Модификујете, ометате, пресрећете или хакујете интернет сајт или апликацију у циљу њиховог онеспособљавања
- Користите специјализоване програме ради преузимања садржаја са сајта или апликације
Ако прекршите било који од ових Услова ми Вас можемо спречити да користите овај интернет сајт на сталном или привременом нивоу. Ми можемо предузети и друге мере, укључујући али не ограничавајући се на то да Вас пријавимо надлежним државним органима, пошаљемо упозорење или поднесемо тужбу.`

export default termsCirText;
