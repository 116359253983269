import PropTypes from "prop-types";
import { createContext, useCallback, useContext, useMemo } from "react";
// hooks
import useLocalStorage from "../../hooks/useLocalStorage";
//
import { defaultSettings } from "./config";
import { defaultPreset, getPresets, presetsOption } from "./presets";

// ----------------------------------------------------------------------

const initialState = {
  ...defaultSettings,
  // Mode
  presetsColor: defaultPreset,
  presetsOption: [],
};

// ----------------------------------------------------------------------

export const SettingsContext = createContext(initialState);

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);

  if (!context) {
    throw new Error("useSettingsContext must be use inside SettingsProvider");
  }

  return context;
};

// ----------------------------------------------------------------------

SettingsProvider.propTypes = {
  children: PropTypes.node
};

export function SettingsProvider({ children }) {
  const [settings, setSettings] = useLocalStorage("settings", defaultSettings);

  // Mode
  const onToggleMode = useCallback(() => {
    const themeMode = settings.themeMode === "light" ? "dark" : "light";
    setSettings({ ...settings, themeMode });
  }, [setSettings, settings]);


  // Reset
  const onResetSetting = useCallback(() => {
    setSettings(defaultSettings);
  }, [setSettings]);

  const value = useMemo(
    () => ({
      ...settings,
      // Mode
      onToggleMode,
      presetsOption,
      presetsColor: getPresets(settings.themeColorPresets),
      // Reset
      onResetSetting,
    }),
    [settings, onToggleMode,
      onResetSetting]
  );

  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
}
