import {Container} from "@mui/system";
import {Box, Typography} from "@mui/material";
import termsCirText from './cirTerms.js';
import termsEnText from './enTerms.js';
import termsHuText from './huTerms.js';
import termsRuText from './ruTerms.js';
import termsSkText from './skTerms.js';
import termsSrText from './srTerms.js';
import {useLocales} from "../../locales";
import {useSearchParams} from "react-router-dom";

export default function TermsPage() {
    const {translate} = useLocales();
    const [searchParams] = useSearchParams();

    const lang = searchParams.get("lang");

    const renderText = () => {
        switch (lang){
            case "cir":
                return termsCirText;
            case "en":
                return termsEnText;
            case "hu":
                return termsHuText;
            case "ru":
                return termsRuText;
            case "sk":
                return termsSkText;
            default: return termsSrText;
        }
    }

    return (
        <Container maxWidth="md" sx={{mt: 4, mb: 4}}>
            <Box sx={{bgcolor: 'background.paper', p: 4, borderRadius: 2, boxShadow: 3}}>
                <Typography variant="h4" component="h1" gutterBottom>
                    {translate("termsTitle")}
                </Typography>
                <Typography variant="body1" paragraph sx={{whiteSpace: 'pre-line'}}>
                    {renderText()}
                </Typography>
            </Box>
        </Container>
    );
}
