import {customNodeAxios} from "../backendNodeAPI";
import {AUTH} from "../../auth/FirebaseContext";

export async function getClientInfo(tin) {
    const token = await AUTH.currentUser.getIdToken(true);
    const PIB = tin?.tin || tin
    if(PIB !== undefined && typeof PIB !== 'object' && PIB.length > 8 && PIB.length < 14){
        return customNodeAxios(token).get(`/getCompanyDetails?TIN=${PIB}`);
    }
    return ""
}