const termsSkText = `Upozornenie: Pred použitím webovej stránky si pozorne prečítajte podmienky používania. Používaním našej webovej stránky potvrdzujete, že ste si prečítali, porozumeli a prijali tieto podmienky používania (ďalej len Podmienky). Verejná mestská dopravná spoločnosť "Novi Sad", Novi Sad si vyhradzuje právo kedykoľvek zmeniť Podmienky vykonaním zmien v tomto texte. Odporúčame vám z času na čas navštíviť túto webovú stránku, aby ste zistili, či sa podmienky od vašej poslednej návštevy nezmenili.
Autobusová stanica "Novi Sad" funguje ako súčasť Dopravného podniku mesta "Novi Sad", Novi Sad, ktorého zriaďovateľom je Zastupiteľstvo mesta Nový Sad. Autobusová stanica "Novi Sad" poskytuje staničnú obsluhu dopravcom a cestujúcim od decembra 1967 a dnes vykonáva činnosť poskytovania staničnej obsluhy v zmysle ustanovení aktuálneho zákona o preprave cestujúcich v cestnej premávke a Všeobecných Obchodné podmienky autobusových staníc. V súvislosti s vyššie uvedeným Ministerstvom infraštruktúry, výstavby a dopravy vydalo licenciu na poskytovanie služieb na stanici Dopravnému podniku mesta Nový Sad, Nový Sad. V súlade s platným zákonom o preprave cestujúcich v cestnej premávke a platnými Všeobecnými obchodnými podmienkami autobusových staníc sme povinní Vás informovať o:
- Áno, autobusová stanica "Novi Sad" robí rezervácie a predáva lístky na medzimestskú a medzinárodnú osobnú dopravu v mene a na účet dopravcu. Vlakový lístok je dokladom o uzavretí prepravnej zmluvy medzi dopravcom a cestujúcim;
- Cestujúci je povinný mať pri sebe doklad potvrdzujúci zvolenú zľavu (index pre študentov, zdravotný preukaz pre deti, dôchodková kontrola...).
- že činnosť poskytovania služieb na stanici zahŕňa: podávanie avíz týkajúcich sa času odchodu a príchodu autobusov, ceny prepravy, názvu dopravcu a pod., predaj cestovných lístkov na vlak a vydávanie iných prepravných dokladov súvisiacich na prepravu; nastupovanie a vystupovanie cestujúcich; ubytovanie a úschova batožín a príjem a odoslanie autobusov;
- že užívateľmi služieb stanice sú dopravcovia a cestujúci;
- Poskytovateľ služby je povinný predávať a vydávať lístky na vlak a iné cestovné lístky v súlade s registrovaným alebo schváleným cestovným poriadkom a platným cenníkom.
Poskytovateľom služby je:
Podnik mestskej hromadnej dopravy "Novi Sad", Novi Sad
Futoški cesta číslo 46
21137 Nový Sad
MB: 08041822
PIB: 100277615
Autobusová stanica „Novi Sad“ sa nachádza na adrese Bul Jaše Tomić č. 6 v Novom Sade.

Obsah zverejnený na webovej stránke je majetkom Dopravného podniku mesta „Novi Sad“, Nový Sad a môže byť použitý len na nekomerčné účely, pričom musia byť dodržané všetky upozornenia týkajúce sa autorských práv. Nie je dovolené akýmkoľvek spôsobom upravovať obsah tejto stránky alebo aplikácie, ani ju reprodukovať alebo verejne zobrazovať, predvádzať, distribuovať alebo inak používať na verejné alebo komerčné účely. Ak chcete použiť obsah zverejnený na tejto webovej stránke alebo v aplikácii na iné účely, ako sú tu uvedené, musíte nás kontaktovať a získať povolenie.
Pri používaní našej webovej stránky nie je dovolené:
- Zasahovať alebo deaktivovať akékoľvek funkcie súvisiace s bezpečnosťou na stránke alebo funkcie, ktoré zabraňujú alebo obmedzujú používanie alebo kopírovanie dostupného obsahu
- Zverejňujete alebo nahrávate na webovú stránku alebo aplikáciu akýkoľvek materiál, ktorý je výhražný, hanlivý, obscénny, urážlivý, pornografický alebo urážlivý. Tiež materiál, pomocou ktorého môžete vyvolať rasovú nenávisť, spôsobiť obťažovanie alebo akékoľvek nepríjemnosti z dôvodu porušenia súkromia, dôverovať určitým diskriminačným, výhražným alebo provokatívnym komentárom
- Vo svojom profile uvádzate nesprávne informácie týkajúce sa osobných údajov
- Používať osobné údaje niekoho iného bez súhlasu tejto osoby alebo nepravdivo vyhlasovať, že konáte v mene tretej osoby, spoločnosti alebo organizácie
- Zapojiť sa do akéhokoľvek nezákonného alebo nezákonného konania
- Upravíte, narušíte, zachytíte alebo hacknete webovú stránku alebo aplikáciu, aby ste ich deaktivovali
- Na sťahovanie obsahu z lokality alebo aplikácie používajte špecializované programy
Ak porušíte ktorúkoľvek z týchto Podmienok, môžeme vám zabrániť v používaní tejto webovej stránky na trvalom alebo dočasnom základe. Môžeme podniknúť ďalšie kroky, vrátane, ale nie výlučne, nahlásenia vás príslušným vládnym orgánom, odoslania varovania alebo podania žaloby.`
export default termsSkText;
