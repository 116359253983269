import axios from "axios";

const DEV = "http://127.0.0.1:5001/buska-rs/europe-central2/api/"
const PROD_TEST = "https://api-lw64c4rxja-lm.a.run.app/"
const PROD = "https://buska-rs.web.app/api/"

const BASE_URL = process.env.NODE_ENV === 'development' ? PROD_TEST : PROD_TEST;

export const customNodeAxios = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: false,
});


customNodeAxios.interceptors.request.use(request => {

    return request;
}, error => {

    return Promise.reject(error);
});

customNodeAxios.interceptors.response.use(response => {

    return response;
}, error => {

    if (error.response) {

    }
    return Promise.reject(error);
});
