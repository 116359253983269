import {Container} from "@mui/system";
import {Box, Typography} from "@mui/material";
import {useLocales} from "../../locales";
import termsCirText from "../TermsPage/cirTerms";
import termsEnText from "../TermsPage/enTerms";
import termsHuText from "../TermsPage/huTerms";
import termsRuText from "../TermsPage/ruTerms";
import termsSkText from "../TermsPage/skTerms";
import termsSrText from "../TermsPage/srTerms";
import {useSearchParams} from "react-router-dom";

export default function PrivacyPage() {
    const {translate} = useLocales();
    const [searchParams] = useSearchParams();

    const lang = searchParams.get("lang");

    const renderText = () => {
        switch (lang){
            case "cir":
                return termsCirText;
            case "en":
                return termsEnText;
            case "hu":
                return termsHuText;
            case "ru":
                return termsRuText;
            case "sk":
                return termsSkText;
            default: return termsSrText;
        }
    }

    return (
        <Container maxWidth="md" sx={{mt: 4, mb: 4}}>
            <Box sx={{bgcolor: 'background.paper', p: 4, borderRadius: 2, boxShadow: 3}}>
                <Typography variant="h4" component="h1" gutterBottom>
                    {translate("privacyTitle")}
                </Typography>
                <Typography variant="body1" paragraph>
                    {renderText()}
                </Typography>
            </Box>
        </Container>
    )
}
