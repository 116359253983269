const termsEnText = `Attention: please read the terms of use carefully before using the website. By using our website, you confirm that you have read, understood and accepted these terms of use (hereinafter: Terms). Public city transport company "Novi Sad", Novi Sad reserves the right to change the Terms at any time by making changes to this text. We suggest that you visit this website from time to time to see if the Terms may have changed since your last visit.
Bus station "Novi Sad" operates as part of the Public City Transport Company "Novi Sad", Novi Sad, which is founded by the Assembly of the City of Novi Sad. The "Novi Sad" bus station has been providing station services to carriers and passengers since December 1967, and today it performs its activity of providing station services in accordance with the provisions of the current Law on the Transportation of Passengers in Road Traffic and the General Conditions of Business of Bus Stations. In connection with the above, the Ministry of Infrastructure, Construction and Transport issued a license for the provision of station services to the Public City Transport Company "Novi Sad", Novi Sad. In accordance with the valid Law on the Transportation of Passengers in Road Traffic and the valid General Terms and Conditions of Bus Stations, we are obliged to inform you of the following:
- Yes, Bus station "Novi Sad" makes reservations and sells tickets for intercity and international passenger transportation in the name and on behalf of the carrier. The train ticket is proof that the contract of carriage has been concluded between the carrier and the passenger;
- The passenger is required to have a document confirming the selected discount (index for students, health card for children, pension check...).
- That the activity of providing station services includes: giving notices related to the time of departure and arrival of buses, the price of transportation, the name of the carrier, etc., the sale of train tickets and the issuance of other transportation documents related to transportation; boarding and disembarking passengers; accommodation and storage of luggage and reception and dispatch of buses;
- That the users of station services are carriers and passengers;
- That the service provider is obliged to sell and issue train tickets and other transport tickets in accordance with the registered or approved timetable and valid price list.
The service provider is:
Public city transport company "Novi Sad", Novi Sad
Futoški put number 46
21137 Novi Sad
MB: 08041822
PIB: 100277615
The "Novi Sad" bus station is located at Bul Jaše Tomić no. 6 in Novi Sad.

The contents published on the website are the property of the Public City Transport Company "Novi Sad", Novi Sad and can only be used for non-commercial purposes, whereby all copyright warnings must be observed. It is not permitted to modify the content of this site or the application in any way, nor to reproduce or publicly display, perform, distribute or otherwise use it for public or commercial purposes. If you want to use the contents published on this website or application for purposes other than those listed here, you need to contact us to obtain permission.
When using our website you are not allowed to:
- Interfere with or disable any security-related features on the Site or features that prevent or restrict the use or copying of available content
- You post or upload to the website or application any material that is threatening, defamatory, obscene, offensive, pornographic or abusive. Also material with which you can initiate racial hatred, cause harassment or any inconvenience due to violation of privacy, trust certain discriminatory, threatening or provocative comments
- You provide incorrect information regarding personal data in your profile
- Use someone else's personal information without that person's permission or falsely represent that you are acting on behalf of a third person, company or organization
- Engage in any illegal or unlawful conduct
- You modify, disrupt, intercept or hack the website or application in order to disable them
- Use specialized programs to download content from a site or application
If you breach any of these Terms, we may prevent you from using this website on a permanent or temporary basis. We may take other actions, including but not limited to reporting you to the relevant government authorities, sending a warning or filing a lawsuit.`
export default termsEnText;
