const THEME_PATH = "esir-bus-portal-theme";
const SESSION_ID_PATH = "esir-bus-portal-session_id";
const APP_VERSION_PATH = "esir-bus-portal-app-version";
const SESSION_LANGUAGE = "esir-bus-portal-language";

// ----------- VERZIJA -------------------
export function getSessionAppVersion() {
    return localStorage.getItem(APP_VERSION_PATH)
        ? parseFloat(localStorage.getItem(APP_VERSION_PATH))
        : null;
}

export function setSessionAppVersion(version) {
    localStorage.setItem(APP_VERSION_PATH, version.toString());
}

// ----------- JEZIK -------------------
export const getSessionLanguage = () => {
    return localStorage.getItem(SESSION_LANGUAGE);
};

export const setSessionLanguage = (language) => {
    return localStorage.setItem(SESSION_LANGUAGE, language);
};

// ----------- SESIJA -------------------

export function setSessionId(sessionId) {
    localStorage.setItem(SESSION_ID_PATH, sessionId);
}

export function getSessionId() {
    return localStorage.getItem(SESSION_ID_PATH);
}

export function deleteLocalSessionId() {
    localStorage.removeItem(SESSION_ID_PATH);
}

// ----------- TEMA -------------------
export function setSessionTheme(theme) {
    localStorage.setItem(THEME_PATH, theme);
}

export function getSessionTheme() {
    let theme = localStorage.getItem(THEME_PATH);
    return theme ? theme : "light";
}

// ----------- OSTALO -------------------
export function clearSession(isLogout = false) {
    localStorage.clear();
}


// ---- LOGOVANJE --------
export function setSession(data){
    localStorage.setItem("USER", JSON.stringify(data));
}

export const getSession = () => {
    const user = localStorage.getItem("USER");
    return JSON.parse(user)
};
export function removeSession(data){
    localStorage.removeItem("USER");
}