import {combineReducers} from "redux";
import storage from "redux-persist/lib/storage";
import {discountReducer} from "./slices/discounts";
import customersReducer from "./slices/customers";
import lineStationsReducer from "./slices/lineStations";
// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: "root",
    storage,
    keyPrefix: "redux-",
    whitelist: []
};

const rootReducer = combineReducers({
    discounts: discountReducer,
    customers: customersReducer,
    lineStations: lineStationsReducer,
});

export {rootPersistConfig, rootReducer};
