import cir from "./cir";

const sk = {
    bannerTitle: "Истражите повољне цене\n" +
        "аутобуских карата за\n" +
        "вашу наредну авантуру",
    bannerSubTitle: "Једноставно упоредите и резервишите ваше следеће путовање",
    appName: "НС портал",
    signIn: "Пријавите се",
    signOut: "Одјавите се",
    home: "Почетна",
    profile: "Профил",
    startStation: "Почетна станица",
    endStation: "Крајња станица",
    startDate: "Датум поласка",
    endDate: "Датум повратка",
    passengers: "Путници",
    search: "Претражите",
    optional: "Опционално",
    mostFavDestinations: "Најчешће изабране дестинације",
    startingFrom: "почевши од",
    rsd: "дин",
    downloadOurApp: "Преузмите нашу апликацију",
    singUpForNotifications: "Пријавите се за обавештења",
    termsOfUse: "Услови коришћења",
    privatePolicy: "Политика приватности",
    appDescription: "S jednoduchým používateľským rozhraním, rýchlym vyhľadávaním a bezpečnou platbou, naša aplikácia vám umožňuje rezervovať si a kúpiť autobusové lístky z pohodlia vášho mobilného zariadenia len v niekoľkých krokoch. Cestovanie nikdy nebolo jednoduchšie – preskúmajte destinácie, vyberte dátumy a vytvorte nezabudnuteľné zážitky s našou intuitívnou aplikáciou.",
    yourEmailAddress: "Vaša e-mailová adresa",
    passengersDetails: "Detaily cestujúcich",
    seatIsNotSelected: "Miesto nie je vybrané",
    selectGender: "Pohlavie",
    male: "Mužské",
    female: "Ženské",
    other: "Iné",
    selectSeat: "Vyberte sedadlo",
    contactDetails: "Kontaktné údaje",
    countryFrom: "Krajina pôvodu",
    contactPhone: "Kontaktný telefón",
    email: "E-mail",
    checkNotification: "Prijímať oznámenia o ponukách",
    documents: "Dokumenty",
    passport: "Pas",
    documentNumber: "Číslo dokumentu",
    ticketReview: "Prehľad lístka",
    refund: "Vrátenie peňazí",
    noRefund: "Bez vrátenia",
    travelConditions: "Podmienky cestovania",
    condition1: "Lístok je potrebné ukázať vodičovi pri nástupe do vozidla",
    condition2: "Lístok môžete nájsť vo vašom účte alebo e-maile po kúpe",
    baggage: "Batožina",
    reservationTime: "Platnosť rezervácie",
    tripDetail: "Detaily cesty",
    invoiceDetails: "Detaily faktúry",
    passenger: "Cestujúci",
    basic: "Základ",
    vat: "DPH",
    discount: "Zľava",
    discountType: "Typ zľavy",
    numberDiscountType: "Číslo dokladu zľavy",
    total: "Celkom",
    name: "Meno",
    surname: "Priezvisko",
    phone: "Telefón",
    bDay: "Dátum narodenia",
    sex: "Pohlavie",
    street: "Ulica",
    zip: "PSČ",
    city: "Mesto",
    state: "Štát",
    update: "Aktualizovať",
    chooseRightTransport: "Vyberte správny dopravný prostriedok",
    foundResults: "Nájdené výsledky",
    cheapest: "Najlacnejšie",
    fastest: "Najrýchlejšie",
    earliest: "Najskôr",
    filter: "Filter",
    numberOfFreeSeats: "Počet voľných miest",
    numberOfStation: "Číslo stanice",
    ticketPrice: "CENA LÍSTKA",
    advertisementTitle: "Reklamná časť",
    adults: "Dospelí",
    youth: "Mladí",
    seniors: "Seniori",
    subscribe: "Prihlásiť sa",
    searchResult: "Výsledok vyhľadávania",
    routeDetails: "Detaily trasy",
    buyTicket: "Kúpiť lístok",
    stationsList: "Zobraziť všetky stanice",
    successfulPurchase: "Úspešný nákup",
    busTicket: "Autobusový lístok",
    invNumber: "Číslo faktúry",
    line: "Linka",
    departure: "Odchod",
    return: "Návrat",
    departureTime: "Čas odchodu",
    returnTime: "Čas návratu",
    contact: "Kontaktné údaje",
    note: "Poznámka",
    downloadTicket: "Stiahnuť lístok",
    paidAmount: "Zaplatená suma",
    paymentMethod: "Spôsob platby",
    carrierInfo: "Informácie o prepravcovi",
    carrierName: "Názov prepravcu",
    contactNumber: "Kontaktné číslo",
    goHome: "Ísť na začiatok",
    pay: "Zaplatiť",
    back: "Späť",
    userProfile: "Profil používateľa",
    personalInfo: "Osobné údaje",
    boughtTickets: "Kúpené lístky",
    logout: "Odhlásiť sa",
    updatePhoto: "Aktualizovať fotografiu",
    personalData: "Osobné údaje",
    passwordChange: "Zmena hesla",
    oldPassword: "Staré heslo",
    newPassword: "Nové heslo",
    welcomeBack: "Vitajte späť!",
    loginGoogle: "Pokračovať s Google",
    loginEmail: "Pokračovať s e-mailom",
    login: "Prihlásiť sa",
    forgottenPassword: "Zabudli ste heslo?",
    dontHaveAcc: "Nemáte účet? ",
    haveAcc: "Už máte účet? ",
    registration: "Registrovať",
    password: "Heslo",
    rePassword: "Zopakovať heslo",
    privilegeType: "Typ výhody",
    privilegeNumber: "Číslo výhody",
    or: "Alebo",
    createAccount: "Vytvoriť účet",
    receiveNotifications: "Chcem dostávať oznámenia o akciách",
    createAccountDescriptionOne: "Rýchlejšie kúpiť autobusové lístky na ďalšie cesty",
    createAccountDescriptionTwo: "Zobraziť históriu zakúpených lístkov a nadchádzajúce cesty",
    termsAccept: "Vytvorením účtu súhlasíte s Podmienkami a Zásadami ochrany osobných údajov a \nsúhlasíte s prijímaním e-mailov, od ktorých sa môžete kedykoľvek odhlásiť.",
    passwordResetTitle: "Obnoviť heslo",
    backToLogin: "Späť na prihlásenie",
    passwordResetText: "Zadajte svoju e-mailovú adresu použitú pri registrácii na portáli a my vám pošleme \nodkaz na obnovu hesla.",
    appSectionBulletOneTitle: "Jednoduchá rezervácia, rýchla cesta",
    appSectionBulletTwoTitle: "Vyhľadávanie na mieru",
    appSectionBulletThreeTitle: "Mobilná sloboda",
    appSectionBulletOneDesc: "Ponúkame vám aplikáciu s jednoduchým používateľským rozhraním, rýchlym vyhľadávaním a bezpečnou platbou. Všetko, čo potrebujete, je len niekoľko krokov na rezerváciu a kúpu autobusových lístkov priamo z vášho mobilného zariadenia.",
    appSectionBulletTwoDesc: "Preskúmajte destinácie, ktoré vás zaujímajú, vyberte dátumy, ktoré vám vyhovujú, a to všetko s rýchlym vyhľadávaním, ktoré vám umožňuje ušetriť čas a ľahko plánovať vašu cestu.",
    appSectionBulletThreeDesc: "Rezervujte si a kúpte autobusové lístky, kým ste na ceste, priamo z vášho mobilného zariadenia. Poskytujeme vám pohodlie a flexibilitu pre riadenie vašich ciest kdekoľvek a kedykoľvek.",
    futureTrips: "Nadchádzajúce cesty",
    completedTrips: "Dokončené cesty",
    cancelledTrips: "Zrušené cesty",
    returnTicket: "Spiatočný lístok",
    trips: "Cesty",
    save: "Uložiť",
    noOptions: "Žiadne možnosti",
    years: "rokov",
    error: "Chyba servera",
    noResult: "Žiadne výsledky",
    totalSelected: "Vybrané celkom",
    endStationError: "Vyberte konečnú stanicu",
    passengersAmountError: "Vyberte aspoň jedného cestujúceho",
    subscribeSuccess: "Úspešne ste sa prihlásili na odber oznámení.",
    emailError: "Zadajte platnú e-mailovú adresu",
    invalidTicket: "Kúpili ste si prednostný lístok na vlak. Pri využití prepravnej služby ste povinní na požiadanie vlakového personálu poskytnúť k nahliadnutiu doklad, na základe ktorého možno určiť, že si uplatňujete právo na zvolenú výhodu.",
    serbia: "Srbsko",
    albania: "Albánsko",
    bih: "Bosna a Hercegovina",
    bulgaria: "Bulharsko",
    montenegro: "Čierna Hora",
    greece: "Grécko",
    croatia: "Chorvátsko",
    northMacedonia: "Macedónsko",
    romania: "Rumunsko",
    slovenia: "Slovinsko",
    turkey: "Turecko",
    required: "Povinné pole",
    invalidEmail: "Neplatná e-mailová adresa",
    passwordsMustMatch: "Heslá sa musia zhodovať",
    passwordRequirements: "Heslo musí obsahovať aspoň jedno veľké písmeno, jedno číslo a jeden špeciálny znak",
    send: "Odoslať",
    badUsernameAndPassword: "Nesprávny e-mail alebo heslo!",
    emailAlreadyInUse: "E-mailová adresa je už používaná!",
    invNumb: "Číslo faktúry",
    startSt: "Začiatočná stanica",
    withBaggage: "S batožinou",
    findCheap: "Nájdite lacné",
    busTicketsFor: "autobusové lístky pre",
    yourNextTrip: "vašu ďalšiu cestu",
    findCheapBusTicketsForYourFavoriteDestinations: "Nájdite lacné autobusové lístky pre vaše obľúbené destinácie",
    easyCompareAndReservation: "Ľahko porovnajte a rezervujte svoju ďalšiu cestu",
    subscribeForNewsletter: "Prihláste sa teraz, aby ste dostávali atraktívne špeciálne ponuky a informácie o najlepších túrach!",
    successMailReset: "Úspešne ste zmenili heslo!",
    emailErrorTitle: "Upozornenie! E-mailová adresa je povinná",
    emailErrorDesc: "Prosím, zadajte vašu e-mailovú adresu, aby ste mohli po úspešnom nákupe dostať faktúru a elektronický lístok.",
    invalidEmailErrorDesc: "Zadajte správnu e-mailovú adresu, aby ste mohli po úspešnom nákupe dostať faktúru a elektronický lístok.",
    studentsDiscount: "Študentský preukaz",
    seniorsDiscount: "Dôchodcovská karta",
    seatNumber: "Číslo sedadla",
    seatNumberNotDefined: "Sedadlo nie je číslované",
    emailHelper: "E-mailová adresa je povinný údaj",
    stationInfo: "Informácie o stanici",
    stationName: "Názov",
    stationService: "Stanica služby",
    profileIsUpdated: "Profil bol úspešne aktualizovaný",
    profileIsUpdatedError: "Došlo k chybe",
    directionType: "Typ smeru",
    oneWay: "Jednosmerný",
    refundOnlyAtStation: "Vrátenie peňazí je možné na pokladni",
    agreeForReturn: "Súhlasím s uvedenými podmienkami cestovania",
    tripType: "Typ lístka",
    returnTicketType: "Spiatočný lístok",
    oneWayTicketType: "Jednosmerný lístok",
    agreementErrorTitle: "Podmienky cestovania!",
    agreementErrorDesc: "Musíte potvrdiť, že súhlasíte s podmienkami cestovania",
    returnTimePolicyDaysValidation: "Spiatočný lístok je otvorený 90* dní",
    arrivalsDepartures: "Príchody a odchody",
    depTime: "Odchod",
    depStation: "Východisková stanica",
    lineName: "Linka",
    expectedTime: "Očakávaný príchod",
    carrier: "Dopravca",
    arrivals: "Príchody",
    departures: "Odchody",
    arrived: "Prišiel",
    notArrived: "Čaká sa",
    peron: "Perón",
    status: "Stav",
    searchDepStation: "Východisková stanica",
    searchLineName: "Linka",
    searchCarrier: "Dopravca",
    date: "Dátum",
    today: "Dnes",
    yesterday: "Včera",
    tomorrow: "Zajtra",
    isAgreedForReturnCondition: "Spiatočný lístok je potrebné overiť na okienku AS pred spiatočnou cestou",
    arrAndDep: "Príchody / odchody",
    agreeForReturnLikeSo: "Súhlasím s uvedenými podmienkami cesty ako aj s",
    termsOfUse2: "podmienkami používania",
    privatePolicy2: "politikou súkromia",
    and: "a",
    invalidPerson: "Zľava pre invalidov",
    hideDetails: "Skryť detaily",
    showAllStations: "Zobraziť všetky stanice",
    invalidCardNumber: "Číslo invalidného preukazu",
    issuePlace: "Miesto vydania",
    documentErrorDesc: "Musíte potvrdiť, že súhlasíte s predložením dokumentu pri nástupe do vozidla",
    documentErrorTitle: "Povinný dokument!"
}
export default sk;
