const cir = {
    bannerTitle: "Истражите повољне цене\n" +
        "аутобуских карта за\n" +
        "вашу наредну авантуру",
    bannerSubTitle: "Једноставно упоредити и резервишите ваше следеће путовање",
    appName: "НС портал",
    signIn: "Пријави се",
    signOut: "Одјави се",
    home: "Почетна",
    profile: "Профил",
    startStation: "Почетна станица",
    endStation: "Крајња станица",
    startDate: "Датум поласка",
    endDate: "Датум повратка",
    passengers: "Путници",
    search: "Претражи",
    optional: "Опционо",
    mostFavDestinations: "Најчешће биране дестинације",
    startingFrom: "већ од",
    rsd: "дин",
    downloadOurApp: "Преузмите нашу апликацију",
    singUpForNotifications: "Пријавите се за нова обавештења",
    termsOfUse: "Услови коришћења",
    privatePolicy: "Политика приватности",
    appDescription: "Са једноставним корисничким интерфејсом, брзим претрагама и сигурним плаћањем, наша апликација " +
        "вам омогућава да у свега неколико корака резервишете и купите аутобуске карте из удобности свог мобилног уређаја. " +
        "Путовање никада није било лакше – истражите дестинације, бирајте датуме и стварајте незаборавна искуства уз нашу " +
        "интуитивну апликацију.",
    yourEmailAddress: "Ваша е-адреса",
    passengersDetails: "Детаљи путника",
    seatIsNotSelected: "Место седења није дефинисано",
    selectGender: "Пол",
    male: "Мушки",
    female: "Женски",
    other: "Остало",
    selectSeat: "Изабери седиште",
    contactDetails: "Контакт подаци",
    countryFrom: "Држава рођења",
    contactPhone: "Контакт телефон",
    email: "Е-пошта",
    checkNotification: "Примај обавештења везана за понуде",
    documents: "Документи",
    passport: "Пасош",
    documentNumber: "Број документа",
    ticketReview: "Преглед карте",
    refund: "Рефундирање",
    noRefund: "Без рефундирања",
    travelConditions: "Услови путовања",
    condition1: "Карту је потребно показати возачу при уласку у возило",
    condition2: "Карту можете пронаћи на вашем налогу или мејлу након куповине",
    baggage: "Пртљаг",
    reservationTime: "Важење резервације",
    tripDetail: "Детаљи пута",
    invoiceDetails: "Ставке на рачуну",
    passenger: "Путник",
    basic: "Основица",
    vat: "Порез",
    discount: "Остварен попуст",
    discountType: "Врста повластице",
    numberDiscountType: "Број документа повластице",
    total: "Укупно",
    name: "Име",
    surname: "Презиме",
    phone: "Телефон",
    bDay: "Датум рођења",
    sex: "Пол",
    street: "Улица",
    zip: "Поштански број",
    city: "Град",
    state: "Држава",
    update: "Ажурирај",
    chooseRightTransport: "Изаберите одговарајући превоз",
    foundResults: "Резултата",
    cheapest: "Најјефтинија",
    fastest: "Најбржа",
    earliest: "Најранија",
    filter: "Филтер",
    numberOfFreeSeats: "Слободних места",
    numberOfStation: "Број перона",
    ticketPrice: "ЦЕНА КАРТЕ",
    advertisementTitle: "Рекламни део",
    adults: "Одрасли",
    youth: "Млади",
    seniors: "Сениори",
    subscribe: "Пријави се",
    searchResult: "Резултат претраге",
    routeDetails: "Детаљи релације",
    buyTicket: "Купи карту",
    stationsList: "Види све станице",
    successfulPurchase: "Успешна куповина",
    busTicket: "Аутобуска карта",
    invNumber: "Број рачуна",
    line: "Линија",
    departure: "Полазак",
    return: "Повратак",
    departureTime: "Време поласка",
    returnTime: "Време повратка",
    contact: "Контакт подаци",
    note: "Напомена",
    downloadTicket: "Преузми карту",
    paidAmount: "Уплаћен износ",
    paymentMethod: "Начин плаћања",
    carrierInfo: "Подаци превозника",
    carrierName: "Назив превозника",
    contactNumber: "Контакт телефон",
    goHome: "Иди на почетак",
    pay: "Плати",
    back: "Назад",
    userProfile: "Профил корисника",
    personalInfo: "Личне информације",
    boughtTickets: "Купљене карте",
    logout: "Одјави се",
    updatePhoto: "Промени слику",
    personalData: "Лични подаци",
    passwordChange: "Измена лозинке",
    oldPassword: "Стара лозинка",
    newPassword: "Нова лозинка",
    welcomeBack: "Добродошли назад!",
    loginGoogle: "Настави уз Google",
    loginEmail: "Настави помоћу мејла",
    login: "Пријави се",
    forgottenPassword: "Заборавили сте лозинку?",
    dontHaveAcc: "Немате отворен налог? ",
    haveAcc: "Већ имате налог? ",
    registration: "Региструј се",
    password: "Лозинка",
    rePassword: "Понови лозинку",
    privilegeType: "Тип повластице",
    privilegeNumber: "Број повластице",
    or: "Или",
    createAccount: "Направите налог",
    receiveNotifications: "Желим да примам обавештења о промоцијама",
    createAccountDescriptionOne: "Бржа куповина аутобуских карата за наредна путовања",
    createAccountDescriptionTwo: "Приказ историје купљених карата и предстојећих путовања",
    termsAccept: "Креирањем налога прихватате Услове и Политику приватности и \n" +
        "сагласни сте да примате е-поруке, на које можете да се \n" +
        "одјавите у било ком тренутку.",
    passwordResetTitle: "Обновите лозинку",
    backToLogin: "Повратак на пријављивање",
    passwordResetText: "Унесите вашу мејл адресу коју сте користили за \n" +
        "регистрацију на портал, и ми ћемо Вам послати \n" +
        "линк за обнову лозинке.",
    appSectionBulletOneTitle: "Лака резервација, брзо путовање",
    appSectionBulletTwoTitle: "Претраге по твојој мери",
    appSectionBulletThreeTitle: "Мобилна слобода",
    appSectionBulletOneDesc: "Нудимо ти апликацију с једноставним корисничким интерфејсом, брзим претрагама и сигурним плаћањем. Све што ти треба је само неколико корака како би резервисао/ла и купио/ла аутобуске карте директно са свог мобилног уређаја.",
    appSectionBulletTwoDesc: "Истражи дестинације које те занимају, одабери датуме који ти одговарају, и то све уз брзе претраге које ти омогућавају да уштедиш време и лако планираш своје путовање.",
    appSectionBulletThreeDesc: "Резервиши и купи аутобуске карте док си у покрету, директно са свог мобилног уређаја. Пружамо ти практичност и флексибилност да управљаш својим путовањима било где и било када.",
    futureTrips: "Предстојећа путовања",
    completedTrips: "Реализована путовања",
    cancelledTrips: "Отказана путовања",
    returnTicket: "Повратна",
    trips: "Путовања",
    save: "Сачувај",
    noOptions: "Без резултата",
    years: "година",
    error: "Грешка на серверу",
    noResult: "Нема резултата",
    totalSelected: "Изабрано",
    endStationError: "Изаберите крајњу станицу",
    passengersAmountError: "Изаберите бар једног путника",
    subscribeSuccess: "Успешно сте се пријавили за примање обавештења.",
    emailError: "Унесите валидну мејл адресу",
    invalidTicket: "Купили сте повлашћену возну карту. Приликом коришћења услуге превоза дужни сте да на захтев возног особља пружите на увид исправу на основу које се може утврдити да остварујете право на одабрану повластицу",
    serbia: "Србија",
    albania: "Албанија",
    bih: "Босна и Херцеговина",
    bulgaria: "Бугарска",
    montenegro: "Црна Гора",
    greece: "Грчка",
    croatia: "Хрватска",
    northMacedonia: "Македонија",
    romania: "Румунија",
    slovenia: "Словенија",
    turkey: "Турска",
    required: "Обавезно поље",
    invalidEmail: "Мејл није валидан",
    passwordsMustMatch: "Лозинке морају бити исте",
    passwordRequirements: "Лозинка мора имати бар једно велико слово, један број и један специјални карактер",
    send: "Пошаљи",
    badUsernameAndPassword: "Мејл или лозинка нису валидни!",
    emailAlreadyInUse: "Мејл је већ заузет!",
    invNumb: "Број рачуна",
    startSt: "Почетна станица",
    withBaggage: "Са пртљагом",
    findCheap: "Пронађите јефтине",
    busTicketsFor: "аутобуске карте за",
    yourNextTrip: "ваше следеће путовање",
    findCheapBusTicketsForYourFavoriteDestinations: "Пронађите јефтине аутобуске карте за ваше омиљене дестинације",
    easyCompareAndReservation: "Лако упоредите и резервишите своје следеће путовање",
    subscribeForNewsletter: "Пријавите се сада како бисте примали атрактивне посебне понуде и информације о најбољим турама!",
    successMailReset: "Успешно сте променили лозинку!",
    emailErrorTitle: "Упозорење! Мејл адреса је обавезна",
    emailErrorDesc: "Молимо Вас да унесете вашу мејл адресу како бисте добили рачун и електронску карту након успешне куповине.",
    invalidEmailErrorDesc: "Молимо Вас унесите исправну мејл адресу како бисте добили рачун и електронску карту након успешне куповине.",
    studentsDiscount: "Студентска картица",
    seniorsDiscount: "Пензионерска картица",
    seatNumber: "Број места седења",
    seatNumberNotDefined: "Седиште није нумерисано",
    emailHelper: "Мејл адреса је обавезан податак",
    stationInfo: "Подаци станице",
    stationName: "Назив",
    stationService: "Станична услуга",
    profileIsUpdated: "Успешно сте ажурирали профил",
    profileIsUpdatedError: "Дошло је до грешке",
    directionType: "Смер",
    oneWay: "Један смер",
    refundOnlyAtStation: "Рефундирање је могуће на шалтеру",
    agreeForReturn: "Слажем се са наведеним условима путовања",
    tripType: "Тип карте",
    returnTicketType: "Повратна карта",
    oneWayTicketType: "Један смер",
    agreementErrorTitle: "Услови путовања!",
    agreementErrorDesc: "Морате потврдити да сте сагласни са условима путовања",
    returnTimePolicyDaysValidation: "Повратна карта је отворена 90* дана",
    arrivalsDepartures: "Доласци и одласци",
    depTime: "Полазак",
    depStation: "Полазна станица",
    lineName: "Линија",
    expectedTime: "Очекиван долазак",
    carrier: "Превозник",
    arrivals: "Доласци",
    departures: "Поласци",
    arrived: "Стигао",
    notArrived: "Чека се",
    peron: "Перон",
    status: "Статус",
    searchDepStation: "Полазна станица",
    searchLineName: "Линија",
    searchCarrier: "Превозник",
    date: "Датум",
    today: "Данас",
    yesterday: "Јуче",
    tomorrow: "Сутра",
    isAgreedForReturnCondition: "Повратну карту је пре повратног путовања, потребно оверити на шалтеру АС",
    arrAndDep: "Доласци / поласци",
    agreeForReturnLikeSo: "Слажем се са наведеним условима путовања као и са",
    termsOfUse2: "условима коришћења",
    privatePolicy2: "политиком приватности",
    and: "и",
    invalidPerson: "Попуст за инвалиде",
    hideDetails: "Сакриј детаље",
    showAllStations: "Прикажи све станице",
    invalidCardNumber: "Број инвалидске књижице",
    issuePlace: "Место издавања",
    documentErrorDesc: "Морате потврдити да сте сагласни са давањем документа на увид при уласку у возило",
    documentErrorTitle: "Обавезан документ!",
    privacyTitle: "Политика приватности",
    privacyDescription: "У сваком тренутку можете нам се обратити са неким од наведених захтева:\n" +
        "- да Вам омогућимо приступ Вашим личним подацима, да одговоримо за које сврхе се\n" +
        "користе подаци који се тичу Вас, као и да приступите таквим личним подацима, да\n" +
        "знате сврху обраде, категорије Ваших личних података које чувамо и период током ког\n" +
        "задржавамо податке.\n" +
        "- да захтевате исправку нетачних, погрешно унетих и/или застарелих података;\n" +
        "- да захтевате ограничење обраде Ваших података, уколико оспоравате тачност података\n" +
        "о личности у року који омогућава руковаоцу проверу тачности податка о личности,\n" +
        "уколико сматрате да је обрада незаконита, а не желите да се подаци обришу, ако сте\n" +
        "истакли приговор или ако сматрате да ЈГСП Нови Сад више нису потребни Ваши подаци;\n" +
        "- да поднесете приговор на начин на који обрађујемо Ваше податке, чак и у случајевима\n" +
        "када није спорно да податке обрађујемо у складу са Законом;\n" +
        "- да упутите притужбу Поверенику за заштиту података о личности, у складу са Законом.\n" +
        "За сва додатна питања и захтеве у вези са обрадом података о личности можете да\n" +
        "контактирате наше Лице за заштиту података о личности. Одлуком о одређивању лица\n" +
        "овлашћеног за заштиту података о личности број 9564 директор предузећа је дана 10.11.2021.\n" +
        "године за овлашћено лице именовао Весну Хрњак, а њена мејл адреса је vesna.hrnjak@gspns.rs\n" +
        "\n" +
        "\n" +
        "\n" +
        "6. Ступање на снагу и измена Политике приватности\n" +
        "\n" +
        "\n" +
        "Ова Политика приватности ступа на снагу дана _____________године.\n" +
        "Политика приватности може бити измењена или допуњена у случају промене законских оквира или на захтев надлежног органа (Повереника за заштиту информацију од јавног значаја и заштиту података о личности).\n" +
        "Препоручујемо Вам да редовно пратите измене и допуне Политике приватности на нашем\n" +
        "сајту.",
    termsTitle: "Услови коришћења",
    termsDescription: "Пажња: молимо вас да пажљиво прочитате услове коришћења пре употребе интернет сајта. Коришћењем нашег интернет сајта потврђујете да сте прочитали, разумели и прихватили ове услове коришћења (у даљем тексту: Услови). Јавно градско саобраћајно предузеће „Нови Сад“, Нови Сад задржава право да у било ком тренутку измени Услове уношењем измена у овај текст. Предлажемо да с времена на време посетите ову интернет страницу и проверите да ли су се Услови можда променили од ваше последње посете.\n" +
        "Аутобуска станица „Нови Сад“ послује у склопу Јавног градског саобраћајног предузећа „Нови Сад“, Нови Сад чији је Оснивач Скупштина Града Новог Сада. Аутобуска станица „Нови Сад“ пружа станичне услуге превозницима и путницима од децембра 1967. године а данас своју делатност пружања станичних услуга врши у складу са одредбама важећег Закона о превозу путника у друмском саобраћају и Општим условима пословања аутобуских станица. У вези са горе наведеним Министарство инфраструктуре, грађевине и саобраћаја је Јавном градском саобраћајном предузећу „Нови Сад“, Нови Сад издало лиценцу за пружање станичних услуга. У складу са важећим Законом о превозу путника у друмском саобраћају и важећим Општим условима пословања аутобуских станица дужни смо да Вас обавестимо о следећем:\n" +
        "- Да Аутобуска станица „Нови Сад“ врши резервацију и продају карата у међумесном и међународном превозу путника у име и за рачун превозника. Возна карта је доказ да је закључен Уговор о превозу између превозника и путника;\n" +
        "- Путник је дужан да поседује документ којим се потвђује одабрани попуст (индекс за студенте, здравствену књижицу за децу, чек од пензије…).\n" +
        "- Да делатност пружања станичних услуга обухвата: давање обавештења која се односе на време полазака и долазака аутобуса, цену превоза, назив превозника и сл, продају возних карата и издавање других превозних исправа у вези са превозом; укрцавање и искрцавање путника; смештај и чување пртљага и пријем и отпрему аутобуса;\n" +
        "- Да су корисници станичних услуга превозници и путници;\n" +
        "- Да је пружалац услуга дужан да возне карте и друге превозне карте продаје и издаје у складу са регистрованим односно одобреним редом вожње и важећим ценовником.\n" +
        "Пружалац стничних услуга је:\n" +
        "Јавно градско саобраћајно предузеће „Нови Сад“, Нови Сад\n" +
        "Футошки пут број 46\n" +
        "21137 Нови Сад\n" +
        "МБ: 08041822\n" +
        "ПИБ: 100277615\n" +
        "Аутобуска станица „Нови Сад“ се налази на адреси Бул Јаше Томића број 6 у Новом Саду.\n" +
        "\n" +
        "Садржаји објављени на интернет сајту власништво су Јавног градског саобраћајног предузећа „Нови Сад“, Нови Сад и могу се употребљавати само у некомерцијалне сврхе, при чему се морају поштовати и сва наведена упозорења о ауторским правима. Није дозвољено мењати садржај овог сајта нити апликације ни на који начин, нити га репродуковати или јавно приказивати, изводити, дистрибуирати или на други начин користити у јавне или комерцијалне сврхе. Уколико желите да користите садржаје објављене на овом интернет сајту или апликацији у друге сврхе од овде наведених потребно је да нас контактирате ради добијања дозволе.\n" +
        "Када користите наш интернет сајт није дозвољено да:\n" +
        "- Ометате или онемогућавате било које функције повезане са сигурношћу на сајту или функције које спречавају или ограничавају употребу или копирање доступног садржаја\n" +
        "- Објављујете или преносите на интернет сајт или на апликацију сваки материјал који је претећи, клеветнички, непристојан, увредљив, порнографски или злостављачки. Такође материјал којим можете иницирати расну мржњу, узрокујете узнемиравање или било које непријатности услед кршења приватности, поверења одређеним дискриминационим, претећим или изазивачким коментарима\n" +
        "- Дајете нетачне информације које се тичу личних података у вашем профилу\n" +
        "- Користите туђе личне податке без дозволе те особе или лажно представљате да иступате у име трећег лица, фирме или организације\n" +
        "- Бавите се било којим незаконитим или противправним понашањем\n" +
        "- Модификујете, ометате, пресрећете или хакујете интернет сајт или апликацију у циљу њиховог онеспособљавања\n" +
        "- Користите специјализоване програме ради преузимања садржаја са сајта или апликације\n" +
        "Ако прекршите било који од ових Услова ми Вас можемо спречити да користите овај интернет сајт на сталном или привременом нивоу. Ми можемо предузети и друге мере, укључујући али не ограничавајући се на то да Вас пријавимо надлежним државним органима, пошаљемо упозорење или поднесемо тужбу."

}

export default cir;
