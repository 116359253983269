const sr = {
    bannerTitle: "Istražite povoljne cene\n" +
        "autobuskih karata za\n" +
        "vašu narednu avanturu",
    bannerSubTitle: "Jednostavno uporedite i rezervišite vaše sledeće putovanje",
    appName: "NS Portal",
    signIn: "Prijavi se",
    signOut: "Odjavi se",
    home: "Početna",
    profile: "Profil",
    startStation: "Početna stanica",
    endStation: "Krajnja stanica",
    startDate: "Datum polaska",
    endDate: "Datum povratka",
    passengers: "Putnici",
    search: "Pretraži",
    optional: "Opciono",
    mostFavDestinations: "Najčesće birane destinacije",
    returnStationNotFound: "Nije pronađena krajnja stanica",
    startingFrom: "već od",
    rsd: "din",
    downloadOurApp: "Preuzmite našu aplikaciju",
    singUpForNotifications: "Prijavite se za sva nova obaveštenja",
    termsOfUse: "Uslovi korišćenja",
    privatePolicy: "Politika privatnosti",
    appDescription: "Sa jednostavnim korisničkim interfejsom, brzim pretragama i sigurnim plaćanjem, naša aplikacija " +
        "vam omogućava da u svega nekoliko koraka rezervišete i kupite autobuske karte iz udobnosti svog mobilnog uređaja. " +
        "Putovanje nikada nije bilo lakše – istražite destinacije, birajte datume i stvarajte nezaboravna iskustva uz našu " +
        "intuitivnu aplikaciju.",
    yourEmailAddress: "Vaša e-adresa",
    passengersDetails: "Detalji putnika",
    seatIsNotSelected: "Mesto sedenja nije definisano",
    selectGender: "Pol",
    male: "Muški",
    female: "Ženski",
    other: "Ostalo",
    selectSeat: "Izaberi sedište",
    contactDetails: "Kontakt podaci",
    countryFrom: "Država rođenja",
    contactPhone: "Kontakt telefon",
    email: "E-pošta",
    checkNotification: "Primaj obaveštenja vezana za ponude",
    documents: "Dokumenti",
    passport: "Pasoš",
    documentNumber: "Broj dokumenta",
    ticketReview: "Pregled karte",
    refund: "Refundiranje",
    noRefund: "Bez refundiranja",
    travelConditions: "Uslovi putovanja",
    condition1: "Kartu je potrebno pokazati vozaču pri ulasku u vozilo",
    condition2: "Kartu možete pronaći na vašem nalogu ili mejlu nakon kupovine",
    baggage: "Prtljag",
    reservationTime: "Važenje rezervacije",
    tripDetail: "Detalji puta",
    invoiceDetails: "Stavke na računu",
    passenger: "Putnik",
    basic: "Osnovica",
    vat: "Porez",
    discount: "Ostvaren popust",
    discountType: "Vrsta povlastice",
    numberDiscountType: "Broj dokumenta povlastice",
    total: "Ukupno",
    name: "Ime",
    surname: "Prezime",
    phone: "Telefon",
    bDay: "Datum rođenja",
    sex: "Pol",
    street: "Ulica",
    zip: "Poštanski broj",
    city: "Grad",
    state: "Država",
    update: "Ažuriraj",
    chooseRightTransport: "Izaberite odgovarajući prevoz",
    foundResults: "Rezultata",
    cheapest: "Najjeftinija",
    fastest: "Najbrža",
    earliest: "Najranija",
    filter: "Filter",
    numberOfFreeSeats: "Slobodnih mesta",
    numberOfStation: "Broj perona",
    ticketPrice: "CENA KARTE",
    advertisementTitle: "Reklamni deo",
    adults: "Odrasli",
    youth: "Mladi",
    seniors: "Seniori",
    subscribe: "Prijavi se",
    searchResult: "Rezultat pretrage",
    routeDetails: "Detalji relacije",
    buyTicket: "Kupi kartu",
    stationsList: "Vidi sve stanice",
    successfulPurchase: "Uspešna kupovina",
    busTicket: "Autobuska karta",
    invNumber: "Broj računa",
    line: "Linija",
    departure: "Polazak",
    return: "Povratak",
    departureTime: "Vreme polaska",
    returnTime: "Vreme povratka",
    contact: "Kontakt podaci",
    note: "Napomena",
    downloadTicket: "Preuzmi kartu",
    paidAmount: "Uplaćen iznos",
    paymentMethod: "Način plaćanja",
    carrierInfo: "Podaci prevoznika",
    carrierName: "Naziv prevoznika",
    contactNumber: "Kontakt telefon",
    goHome: "Idi na početak",
    pay: "Plati",
    back: "Nazad",
    userProfile: "Profil korisnika",
    personalInfo: "Lične informacije",
    boughtTickets: "Kupljene karte",
    logout: "Odjavi se",
    updatePhoto: "Promeni sliku",
    personalData: "Lični podaci",
    passwordChange: "Izmena lozinke",
    oldPassword: "Stara lozinka",
    newPassword: "Nova lozinka",
    welcomeBack: "Dobrodošli nazad!",
    loginGoogle: "Nastavi uz Google",
    loginEmail: "Nastavi pomoću mejla",
    login: "Prijavi se",
    forgottenPassword: "Zaboravili ste lozinku?",
    dontHaveAcc: "Nemate otvoren nalog? ",
    haveAcc: "Već imate nalog? ",
    registration: "Registruj se",
    password: "Lozinka",
    rePassword: "Ponovi lozinku",
    privilegeType: "Tip polvastice",
    privilegeNumber: "Broj povlastice",
    or: "Ili",
    createAccount: "Napravite nalog",
    receiveNotifications: "Želim da primam obaveštenja o promocijama",
    createAccountDescriptionOne: "Brža kupovina autobuskih karata za naredna putovanja",
    createAccountDescriptionTwo: "Prikaz istorije kupljenih karata i predstojećih putovanja",
    termsAccept: "Kreiranjem naloga prihvatate Uslove i Politiku privatnosti i \n" +
        "saglasni ste da primate e-poruke, na koje možete da se \n" +
        "odjavite u bilo kom trenutku.",
    passwordResetTitle: "Obnovite lozinku",
    backToLogin: "Povratak na prijavljivanje",
    passwordResetText: "Unesite vašu mejl adresu koji ste koristili za \n" +
        "registraciju na portal, i mi ćemo Vam polasti \n" +
        "link za obnovu lozinke.",
    appSectionBulletOneTitle: "Laka rezervacija, brzo putovanje",
    appSectionBulletTwoTitle: "Pretrage po tvojoj meri",
    appSectionBulletThreeTitle: "Mobilna sloboda",
    appSectionBulletOneDesc: "Nudimo ti aplikaciju s jednostavnim korisničkim interfejsom, brzim pretragama i sigurnim plaćanjem. Sve što ti treba je samo nekoliko koraka kako bi rezervisao/la i kupio/la autobuske karte direktno sa svog mobilnog uređaja.",
    appSectionBulletTwoDesc: "Istraži destinacije koje te zanimaju, odaberi datume koji ti odgovaraju, i to sve uz brze pretrage koje ti omogućavaju da uštediš vreme i lako planiraš svoje putovanje.",
    appSectionBulletThreeDesc: "Rezerviši i kupi autobuske karte dok si u pokretu, direktno sa svog mobilnog uređaja. Pružamo ti praktičnost i fleksibilnost da upravljaš svojim putovanjima bilo gde i bilo kada.",
    futureTrips: "Predstojeća putovanja",
    completedTrips: "Realizovana putovanja",
    cancelledTrips: "Otkazana putovanja",
    returnTicket: "Povratna",
    trips: "Putovanja",
    save: "Sačuvaj",
    noOptions: "Bez rezultata",
    years: "godina",
    error: "Greška na serveru",
    noResult: "Nema rezultata",
    totalSelected: "Izabrano",
    endStationError: "Izaberite krajnju stanicu",
    passengersAmountError: "Izabrite bar jednog putnika",
    subscribeSuccess: "Uspešno ste se prijavili za primanje obaveštenja.",
    emailError: "Unesite validnu mejl adresu",
    invalidTicket: "Kupili ste povlašćenu voznu kartu. Prilikom korišćenja usluge prevoza dužni ste da na zahtev voznog osoblja pružite na uvid ispravu na osnovu koje se može utvrditi da ostvarujete pravo na odabranu povlasticu",
    serbia: "Srbija",
    albania: "Albanija",
    bih: "Bosna i Hercegovina",
    bulgaria: "Bugarska",
    montenegro: "Crna Gora",
    greece: "Grčka",
    croatia: "Hrvatska",
    northMacedonia: "Makedonija",
    romania: "Rumunija",
    slovenia: "Slovenija",
    turkey: "Turska",
    required: "Obavezno polje",
    invalidEmail: "Mejl nije validan",
    passwordsMustMatch: "Lozinke moraju biti iste",
    passwordRequirements: "Loznika mora imati bar jedno veli slovo, jedan broj i jedan specijalni karakter",
    send: "Pošalji",
    badUsernameAndPassword: "Mejl ili lozinka nisu validni!",
    emailAlreadyInUse: "Mejl je već zauzet!",
    invNumb: "Broj racuna",
    startSt: "Pocetna stanica",
    withBaggage: "Sa prtljagom",
    findCheap: "Pronađite jeftine",
    busTicketsFor: "autobuske karte za",
    yourNextTrip: "vaše sledeće putovanje",
    findCheapBusTicketsForYourFavoriteDestinations: "Pronađite jeftine autobuske karte za vaše omiljene destinacije",
    easyCompareAndReservation: "Lako uporedite i rezervišite svoje sledeće putovanje",
    subscribeForNewsletter: "Prijavite se sada kako biste primali atraktivne posebne ponude i informacije o najboljim turama!",
    successMailReset: "Uspešno ste promenili lozinku!",
    emailErrorTitle: "Upozorenje! E-mail adresa je obavezna",
    emailErrorDesc: "Molimo Vas da unesete vašu e-mail adresu kako biste dobili račun i elektronsku kartu nakon uspešne kupovine.",
    invalidEmailErrorDesc: "Molimo Vas unesite ispravnu mejl adresu kako biste dobili račun i elektronsku kartu nakon uspešne kupovine.",
    studentsDiscount: "Studentska kartica",
    seniorsDiscount: "Penzionerska kartica",
    seatNumber: "Broj mesta sedenja",
    seatNumberNotDefined: "Sedište nije numerisano",
    emailHelper: "Mejl adresa je obavezan podatak",
    stationInfo: "Podaci stanice",
    stationName: "Naziv",
    stationService: "Stanična usluga",
    profileIsUpdated: "Uspešno ste ažurirali profil",
    profileIsUpdatedError: "Došlo je do greške",
    directionType: "Smer",
    oneWay: "Jedan smer",
    refundOnlyAtStation: "Refundiranje je moguće na šalteru",
    agreeForReturn: "Slažem se sa navedenim uslovima putovanja",
    tripType: "Tip karte",
    returnTicketType: "Povratna karta",
    oneWayTicketType: "Jedan smer",
    agreementErrorTitle: "Uslovi putovanja!",
    agreementErrorDesc: "Morate potvrditi da ste saglasni sa uslovima putovanja",
    returnTimePolicyDaysValidation: "Povratna karta je otvorena 90* dana",
    arrivalsDepartures: "Dolasci i odlasci",
    depTime: "Polazak",
    depStation: "Polazna stanica",
    lineName: "Linija",
    expectedTime: "Očekivan dolazak",
    carrier: "Prevoznik",
    arrivals: "Dolasci",
    departures: "Polasci",
    arrived: "Stigao",
    realized: "Realizovan",
    notArrived: "Čeka se",
    peron: "Peron",
    status: "Status",
    searchDepStation: "Polazna stanica",
    searchLineName: "Linija",
    searchCarrier: "Prevoznik",
    date: "Datum",
    today: "Danas",
    yesterday: "Juče",
    tomorrow: "Sutra",
    isAgreedForReturnCondition: "Povratnu kartu je pre povratnog putovanja, potrebno overiti na šalteru AS",
    arrAndDep: "Dolasci / polasci",
    agreeForReturnLikeSo: "Slažem se sa navedenim uslovima putovanja kao i sa",
    termsOfUse2: "uslovima korišćenja",
    privatePolicy2: "politikom privatnosti",
    and: "i",
    invalidPerson: "Popust za invalide",
    hideDetails: "Sakrij detalje",
    showAllStations: "Prikaži sve stanice",
    invalidCardNumber: "Broj invalidske knjižice",
    issuePlace: "Mesto izdavanja",
    documentErrorDesc: "Morate potrvditi da ste saglasni sa davanjem dokumenta na uvid pri ulasku u vozilo",
    documentErrorTitle: "Obavezan dokument!",
    noSeatSelectAvailable: "Za ovaj autobus nije moguć odabir sedišta. Sedišta će Vam biti automatski dodeljena.!",
    passengerDataErrorDesc: "Morate popuniti sva polja za putnika sa ispravnim podacima",
    passengerErrorTitle: "Obavezan podaci putnika!",
    downloadTickets: "Preuzmi karte",
    expectedDeparture: "Očekivan polazak",
    clear: "Očisti",
    searchArrStation: "Krajnja stanica",
    stationName2: "Stanica",
    privacyTitle: "Politika privatnosti",
    termsTitle: "Uslovi korišćenja",
    emailConfirmed: "Uspešno ste potvrdili registraciju!",
    invalidCode: "Kod nije validan ili je istekao!",
    invalidLink: "Link nije ispravan!",
    registrationComplete: "Provera registracije",
    redirectionToHomePage: "Bićete preusmereni na početan ekran za nekoliko sekundi...",
    passwordIsReset: "Uspešno ste promenili Vašu lozinku. Bićete preusmereni na početan ekran za nekoliko sekundi...",
    resetPasswordInvalid: "Resetovanje lozinke nije uspelo. Veza je možda istekla ili nevažeća",
    invalidRequest: "Nevažeći zahtev. Pokušajte ponovo",
}

export default sr;
