const termsHuText = `Figyelem: kérjük, figyelmesen olvassa el a használati feltételeket a weboldal használata előtt. Weboldalunk használatával Ön megerősíti, hogy elolvasta, megértette és elfogadja a jelen használati feltételeket (a továbbiakban: Feltételek). Az „Újvidék” városi tömegközlekedési vállalat fenntartja magának a jogot a Feltételek bármikori megváltoztatására a szöveg módosításával. Javasoljuk, hogy időről időre látogassa meg ezt a webhelyet, és ellenőrizze, hogy a Feltételek változtak-e az utolsó látogatása óta.
Az "Újvidék" autóbusz-állomás az Újvidéki Városi Közlekedési Vállalat részeként működik, amelyet Újvidék város közgyűlése alapított. Az „Újvidék” autóbusz-pályaudvar 1967 decembere óta látja el a fuvarozók és az utasok állomási szolgáltatását, ma a közúti személyszállításról szóló hatályos törvény és az általános Az autóbusz-állomások üzleti feltételei. A fentiekkel összefüggésben az Infrastrukturális, Építési és Közlekedési Minisztérium engedélyt adott ki az állomási szolgáltatás nyújtására az Újvidéki Városi Közlekedési Vállalat „Újvidék” részére. A közúti személyszállításról szóló hatályos törvény és az autóbusz-pályaudvarok érvényes Általános Szerződési Feltételei értelmében kötelesek vagyunk tájékoztatni az alábbiakról:
- Igen, az "Újvidék" buszpályaudvar helyfoglalásokat és jegyeket értékesít helyközi és nemzetközi személyszállításra a fuvarozó nevében és megbízásából. A vonatjegy bizonyítja, hogy a fuvarozási szerződés létrejött a fuvarozó és az utas között;
- Az utasnak rendelkeznie kell a választott kedvezményt igazoló okmánnyal (diákoknak index, gyerekeknek egészségügyi kártya, nyugdíj csekk...).
- Az állomási szolgáltatási tevékenység magában foglalja: az autóbuszok indulási és érkezési idejével, a szállítás árával, a fuvarozó nevével stb. kapcsolatos értesítéseket, a vonatjegyek értékesítését és az ezzel kapcsolatos egyéb szállítási okmányok kiállítását. a szállításhoz; utasok be- és kiszállása; poggyász elhelyezése és tárolása, valamint buszok fogadása és feladása;
- Az állomási szolgáltatások igénybevevői fuvarozók és utasok;
- Hogy a szolgáltató köteles a vonatjegyeket és egyéb közlekedési jegyeket a nyilvántartásba vett vagy jóváhagyott menetrend és érvényes árlista szerint értékesíteni és kiadni.
A szolgáltató a következő:
Városi tömegközlekedési vállalat "Novi Sad", Újvidék
Futoški út 46
21137 Újvidék
MB: 08041822
PIB: 100277615
Az "Novi Sad" buszpályaudvar a Bul Jaše Tomić 6. szám alatt található, Újvidéken.

A weboldalon közzétett tartalom az Újvidéki Városi Közlekedési Vállalat tulajdonát képezi, és kizárólag nem kereskedelmi célokra használható fel, ezzel együtt minden szerzői jogi figyelmeztetést be kell tartani. Tilos a jelen oldal vagy az alkalmazás tartalmának bármilyen módon történő módosítása, reprodukálása vagy nyilvános megjelenítése, előállítása, terjesztése vagy egyéb nyilvános vagy kereskedelmi célokra történő felhasználása. Ha az ezen a weboldalon vagy az alkalmazásban közzétett tartalmat az itt felsoroltaktól eltérő célokra szeretné felhasználni, engedélyért lépjen kapcsolatba velünk.
Weboldalunk használata során tilos:
- Megzavarni vagy letiltani a Webhely bármely biztonsági vonatkozású funkcióját, vagy olyan funkciót, amely megakadályozza vagy korlátozza az elérhető tartalom használatát vagy másolását
- Bármilyen fenyegető, rágalmazó, obszcén, sértő, pornográf vagy sértő anyagot tesz közzé vagy tölt fel a webhelyre vagy alkalmazásra. Olyan anyagok is, amelyekkel faji gyűlöletet kelthet, zaklatást vagy magánélet megsértése miatti kellemetlenséget okozhat, megbízhat bizonyos diszkriminatív, fenyegető vagy provokatív megjegyzésekben
- A profiljában szereplő személyes adatokkal kapcsolatban helytelen adatokat ad meg
- Felhasználhatja valaki más személyes adatait az adott személy engedélye nélkül, vagy hamisan állíthatja, hogy egy harmadik személy, vállalat vagy szervezet nevében jár el
- Bármilyen jogellenes vagy törvénytelen magatartást tanúsítani
- Ön módosítja, megzavarja, elfogja vagy feltöri a webhelyet vagy alkalmazást annak letiltása érdekében
- Speciális programokkal tölthet le tartalmat egy webhelyről vagy alkalmazásról
Ha megszegi a jelen Feltételek bármelyikét, előfordulhat, hogy tartósan vagy ideiglenesen megakadályozzuk a webhely használatát. Más intézkedéseket is megtehetünk, beleértve, de nem kizárólagosan, feljelentjük Önt az illetékes kormányzati hatóságoknál, figyelmeztetést küldhetünk vagy pert indíthatunk.`
export default termsHuText;
