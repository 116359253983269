const en = {
    bannerTitle: "Explore affordable bus ticket prices for your next adventure",
    bannerSubTitle: "Easily compare and book your next trip",
    appName: "NS Portal",
    signIn: "Sign in",
    signOut: "Sign out",
    home: "Home",
    profile: "Profile",
    startStation: "Starting station",
    endStation: "End station",
    startDate: "Departure date",
    endDate: "Return date",
    passengers: "Passengers",
    search: "Search",
    optional: "Optional",
    mostFavDestinations: "Most frequently chosen destinations",
    startingFrom: "starting from",
    rsd: "RSD",
    downloadOurApp: "Download our app",
    singUpForNotifications: "Sign up for all new notifications",
    termsOfUse: "Terms of use",
    privatePolicy: "Privacy policy",
    appDescription: "With a simple user interface, fast searches, and secure payment, our app allows you to book and purchase bus tickets in just a few steps from the comfort of your mobile device. Traveling has never been easier – explore destinations, pick dates, and create unforgettable experiences with our intuitive app.",
    yourEmailAddress: "Your email address",
    passengersDetails: "Passenger details",
    seatIsNotSelected: "Seat is not selected",
    selectGender: "Select gender",
    male: "Male",
    female: "Female",
    other: "Other",
    selectSeat: "Select seat",
    contactDetails: "Contact details",
    countryFrom: "Country of birth",
    contactPhone: "Contact phone",
    email: "Email",
    checkNotification: "Receive offer-related notifications",
    documents: "Documents",
    passport: "Passport",
    documentNumber: "Document number",
    ticketReview: "Ticket review",
    refund: "Refund",
    noRefund: "No refund",
    travelConditions: "Travel conditions",
    condition1: "Ticket must be shown to the driver upon entering the vehicle",
    condition2: "You can find the ticket in your account or email after purchase",
    baggage: "Baggage",
    reservationTime: "Reservation validity",
    tripDetail: "Trip details",
    invoiceDetails: "Invoice details",
    passenger: "Passenger",
    basic: "Basic",
    vat: "Tax",
    discount: "Discount amount",
    discountType: "Type of discount",
    numberDiscountType: "Discount document number",
    total: "Total",
    name: "Name",
    surname: "Surname",
    phone: "Phone",
    bDay: "Date of birth",
    sex: "Gender",
    street: "Street",
    zip: "Zip code",
    city: "City",
    state: "State",
    update: "Update",
    chooseRightTransport: "Choose the right transport",
    foundResults: "Results",
    cheapest: "Cheapest",
    fastest: "Fastest",
    earliest: "Earliest",
    filter: "Filter",
    numberOfFreeSeats: "Number of free seats",
    numberOfStation: "Number of platform",
    ticketPrice: "TICKET PRICE",
    advertisementTitle: "Advertisement section",
    adults: "Adults",
    youth: "Youth",
    seniors: "Seniors",
    subscribe: "Subscribe",
    searchResult: "Search result",
    routeDetails: "Route details",
    buyTicket: "Buy ticket",
    stationsList: "See all stations",
    successfulPurchase: "Successful purchase",
    busTicket: "Bus ticket",
    invNumber: "Invoice number",
    line: "Line",
    departure: "Departure",
    return: "Return",
    departureTime: "Departure time",
    returnTime: "Return time",
    contact: "Contact details",
    note: "Note",
    downloadTicket: "Download ticket",
    paidAmount: "Paid amount",
    paymentMethod: "Payment method",
    carrierInfo: "Carrier information",
    carrierName: "Carrier name",
    contactNumber: "Contact number",
    goHome: "Go to home",
    pay: "Pay",
    back: "Back",
    userProfile: "User profile",
    personalInfo: "Personal information",
    boughtTickets: "Bought tickets",
    logout: "Log out",
    updatePhoto: "Update photo",
    personalData: "Personal data",
    passwordChange: "Change password",
    oldPassword: "Old password",
    newPassword: "New password",
    welcomeBack: "Welcome back!",
    loginGoogle: "Continue with Google",
    loginEmail: "Continue with email",
    login: "Log in",
    forgottenPassword: "Forgot password?",
    dontHaveAcc: "Don't have an account? ",
    haveAcc: "Already have an account? ",
    registration: "Register",
    password: "Password",
    rePassword: "Repeat password",
    privilegeType: "Type of privilege",
    privilegeNumber: "Privilege number",
    or: "Or",
    createAccount: "Create account",
    receiveNotifications: "I would like to receive promotional notifications",
    createAccountDescriptionOne: "Faster purchase of bus tickets for upcoming trips",
    createAccountDescriptionTwo: "View the history of purchased tickets and upcoming trips",
    termsAccept: "By creating an account, you accept the Terms and Privacy Policy and agree to receive emails, which you can unsubscribe from at any time.",
    passwordResetTitle: "Reset password",
    backToLogin: "Back to login",
    passwordResetText: "Enter the email address you used to register on the portal, and we will send you a link to reset your password.",
    appSectionBulletOneTitle: "Easy reservation, quick travel",
    appSectionBulletTwoTitle: "Searches tailored to you",
    appSectionBulletThreeTitle: "Mobile freedom",
    appSectionBulletOneDesc: "We offer you an app with a simple user interface, fast searches, and secure payment. All you need is just a few steps to book and purchase bus tickets directly from your mobile device.",
    appSectionBulletTwoDesc: "Explore destinations of interest, choose dates that suit you, all with quick searches that save you time and easily plan your trip.",
    appSectionBulletThreeDesc: "Book and purchase bus tickets on the go, directly from your mobile device. We provide you with the convenience and flexibility to manage your trips anywhere and anytime.",
    futureTrips: "Upcoming trips",
    completedTrips: "Completed trips",
    cancelledTrips: "Cancelled trips",
    returnTicket: "Return ticket",
    trips: "Trips",
    save: "Save",
    noOptions: "No options",
    years: "years",
    error: "Server error",
    noResult: "No results",
    totalSelected: "Selected",
    endStationError: "Select end station",
    passengersAmountError: "Select at least one passenger",
    subscribeSuccess: "You have successfully subscribed to notifications.",
    emailError: "Enter a valid email address",
    invalidTicket: "You have purchased a preferential train ticket. When using the transportation service, you are obliged to provide a document for inspection at the request of the train staff, on the basis of which it can be determined that you exercise the right to the selected privilege",
    serbia: "Serbia",
    required: "Required field",
    invalidEmail: "Invalid email",
    passwordsMustMatch: "Passwords must match",
    passwordRequirements: "Password must have at least one uppercase letter, one number, and one special character",
    send: "Send",
    badUsernameAndPassword: "Invalid email or password!",
    emailAlreadyInUse: "Email is already in use!",
    invNumb: "Invoice number",
    startSt: "Start station",
    withBaggage: "With baggage",
    findCheap: "Find cheap",
    busTicketsFor: "bus tickets for",
    yourNextTrip: "your next trip",
    findCheapBusTicketsForYourFavoriteDestinations: "Find cheap bus tickets to your favorite destinations",
    easyCompareAndReservation: "Easily compare and book your next trip",
    subscribeForNewsletter: "Sign up now to receive attractive special offers and information on the best tours!",
    successMailReset: "Successfully changed your password!",
    emailErrorTitle: "Warning! Email address is required",
    emailErrorDesc: "Please enter your email address to receive a receipt and e-ticket after a successful purchase.",
    invalidEmailErrorDesc: "Please enter a valid email address to receive a receipt and e-ticket after a successful purchase.",
    studentsDiscount: "Student card",
    seniorsDiscount: "Senior card",
    seatNumber: "Seat number",
    seatNumberNotDefined: "Seat is not numbered",
    emailHelper: "Email address is a required field",
    stationInfo: "Station information",
    stationName: "Name",
    stationService: "Station service",
    profileIsUpdated: "Successfully updated profile",
    profileIsUpdatedError: "An error occurred",
    directionType: "Direction",
    oneWay: "One way",
    refundOnlyAtStation: "Refund is possible at the counter",
    agreeForReturn: "I agree with the mentioned travel conditions",
    tripType: "Ticket type",
    returnTicketType: "Return ticket",
    oneWayTicketType: "One way",
    agreementErrorTitle: "Travel conditions!",
    agreementErrorDesc: "You must confirm that you agree with the travel conditions",
    returnTimePolicyDaysValidation: "Return ticket is open for 90* days",
    arrivalsDepartures: "Arrivals and departures",
    depTime: "Departure",
    depStation: "Departure station",
    lineName: "Line",
    expectedTime: "Expected arrival",
    carrier: "Carrier",
    arrivals: "Arrivals",
    departures: "Departures",
    arrived: "Arrived",
    notArrived: "Waiting",
    peron: "Platform",
    status: "Status",
    searchDepStation: "Departure station",
    searchLineName: "Line",
    searchCarrier: "Carrier",
    date: "Date",
    today: "Today",
    yesterday: "Yesterday",
    tomorrow: "Tomorrow",
    isAgreedForReturnCondition: "Before the return journey, the return ticket needs to be validated at the AS counter",
    arrAndDep: "Arrivals / departures",
    agreeForReturnLikeSo: "I agree with the stated travel conditions as well as with",
    termsOfUse2: "terms of use",
    privatePolicy2: "privacy policy",
    and: "and",
    invalidPerson: "Discount for disabled",
    hideDetails: "Hide details",
    showAllStations: "Show all stations",
    invalidCardNumber: "Invalid card number",
    issuePlace: "Issue place",
    documentErrorDesc: "You must confirm that you agree to present the document for inspection upon entering the vehicle",
    documentErrorTitle: "Document required!",
    privacyTitle: "Privacy Policy",
    privacyDescription: "U svakom trenutku možete nam se obratiti sa nekim od navedenih zahteva:\n" +
        "- da Vam omogućimo pristup Vašim ličnim podacima, da odgovorimo za koje svrhe se\n" +
        "koriste podaci koji se tiču Vas, kao i da pristupite takvim ličnim podacima, da\n" +
        "znate svrhu obrade, kategorije Vaših ličnih podataka koje čuvamo i period tokom kog\n" +
        "zadržavamo podatke.\n" +
        "- da zahtevate ispravku netačnih, pogrešno unetih i/ili zastarelih podataka;\n" +
        "- da zahtevate ograničenje obrade Vaših podataka, ukoliko osporavate tačnost podataka\n" +
        "o ličnosti u roku koji omogućava rukovaocu proveru tačnosti podatka o ličnosti,\n" +
        "ukoliko smatrate da je obrada nezakonita, a ne želite da se podaci obrišu, ako ste\n" +
        "istakli prigovor ili ako smatrate da JGSP Novi Sad više nisu potrebni Vaši podaci;\n" +
        "- da podnesete prigovor na način na koji obrađujemo Vaše podatke, čak i u slučajevima\n" +
        "kada nije sporno da podatke obrađujemo u skladu sa Zakonom;\n" +
        "- da uputite pritužbu Povereniku za zaštitu podataka o ličnosti, u skladu sa Zakonom.\n" +
        "Za sva dodatna pitanja i zahteve u vezi sa obradom podataka o ličnosti možete da\n" +
        "kontaktirate naše Lice za zaštitu podataka o ličnosti. Odlukom o određivanju lica\n" +
        "ovlašćenog za zaštitu podataka o ličnosti broj 9564 direktor preduzeća je dana 10.11.2021.\n" +
        "godine za ovlašćeno lice imenovao Vesnu Hrnjak, a njena mejl adresa je vesna.hrnjak@gspns.rs\n" +
        "\n" +
        "\n" +
        "\n" +
        "6. Stupanje na snagu i izmena Politike privatnosti\n" +
        "\n" +
        "\n" +
        "Ova Politika privatnosti stupa na snagu dana _____________godine.\n" +
        "Politika privatnosti može biti izmenjena ili dopunjena u slučaju promene zakonskih okvira ili na zahtev nadležnog organa (Poverenika za zaštitu informaciju od javnog značaja i zaštitu podataka o ličnosti).\n" +
        "Preporučujemo Vam da redovno pratite izmene i dopune Politike privatnosti na našem\n" +
        "sajtu.",
    termsTitle: "Uslovi korišćenja",
    termsDescription: "Pažnja: molimo vas da pažljivo pročitate uslove korišćenja pre upotrebe internet sajta. Korišćenjem našeg internet sajta potvrđujete da ste pročitali, razumeli i prihvatili ove uslove korišćenja (u daljem tekstu: Uslovi). Javno gradsko saobraćajno preduzeće „Novi Sad“, Novi Sad zadržava pravo da u bilo kom trenutku izmeni Uslove unošenjem izmena u ovaj tekst. Predlažemo da s vremena na vreme posetite ovu internet stranicu i proverite da li su se Uslovi možda promenili od vaše poslednje posete.\n" +
        "Autobuska stanica „Novi Sad“ posluje u sklopu Javnog gradskog saobraćajnog preduzeća „Novi Sad“, Novi Sad čiji je Osnivač Skupština Grada Novog Sada. Autobuska stanica „Novi Sad“ pruža stanične usluge prevoznicima i putnicima od decembra 1967. godine a danas svoju delatnost pružanja staničnih usluga vrši u skladu sa odredbama važećeg Zakona o prevozu putnika u drumskom saobraćaju i Opštim uslovima poslovanja autobuskih stanica. U vezi sa gore navedenim Ministarstvo infrastrukture, građevine i saobraćaja je Javnom gradskom saobraćajnom preduzeću „Novi Sad“, Novi Sad izdalo licencu za pružanje staničnih usluga. U skladu sa važećim Zakonom o prevozu putnika u drumskom saobraćaju i važećim Opštim uslovima poslovanja autobuskih stanica dužni smo da Vas obavestimo o sledećem:\n" +
        "- Da Autobuska stanica „Novi Sad“ vrši rezervaciju i prodaju karata u međumesnom i međunarodnom prevozu putnika u ime i za račun prevoznika. Vozna karta je dokaz da je zaključen Ugovor o prevozu između prevoznika i putnika;\n" +
        "- Putnik je dužan da poseduje dokument kojim se potvđuje odabrani popust (indeks za studente, zdravstvenu knjižicu za decu, ček od penzije…).\n" +
        "- Da delatnost pružanja staničnih usluga obuhvata: davanje obaveštenja koja se odnose na vreme polazaka i dolazaka autobusa, cenu prevoza, naziv prevoznika i sl, prodaju voznih karata i izdavanje drugih prevoznih isprava u vezi sa prevozom; ukrcavanje i iskrcavanje putnika; smeštaj i čuvanje prtljaga i prijem i otpremu autobusa;\n" +
        "- Da su korisnici staničnih usluga prevoznici i putnici;\n" +
        "- Da je pružalac usluga dužan da vozne karte i druge prevozne karte prodaje i izdaje u skladu sa registrovanim odnosno odobrenim redom vožnje i važećim cenovnikom.\n" +
        "Pružalac stničnih usluga je:\n" +
        "Javno gradsko saobraćajno preduzeće „Novi Sad“, Novi Sad\n" +
        "Futoški put broj 46\n" +
        "21137 Novi Sad\n" +
        "MB: 08041822\n" +
        "PIB: 100277615\n" +
        "Autobuska stanica „Novi Sad“ se nalazi na adresi Bul Jaše Tomića broj 6 u Novom Sadu.\n" +
        "\n" +
        "Sadržaji objavljeni na internet sajtu vlasništvo su Javnog gradskog saobraćajnog preduzeća „Novi Sad“, Novi Sad i mogu se upotrebljavati samo u nekomercijalne svrhe, pri čemu se moraju poštovati i sva navedena upozorenja o autorskim pravima. Nije dozvoljeno menjati sadržaj ovog sajta niti aplikacije ni na koji način, niti ga reprodukovati ili javno prikazivati, izvoditi, distribuirati ili na drugi način koristiti u javne ili komercijalne svrhe. Ukoliko želite da koristite sadržaje objavljene na ovom internet sajtu ili aplikaciji u druge svrhe od ovde navedenih potrebno je da nas kontaktirate radi dobijanja dozvole.\n" +
        "Kada koristite naš internet sajt nije dozvoljeno da:\n" +
        "- Ometate ili onemogućavate bilo koje funkcije povezane sa sigurnošću na sajtu ili funkcije koje sprečavaju ili ograničavaju upotrebu ili kopiranje dostupnog sadržaja\n" +
        "- Objavljujete ili prenosite na internet sajt ili na aplikaciju svaki materijal koji je preteći, klevetnički, nepristojan, uvredljiv, pornografski ili zlostavljački. Takođe materijal kojim možete inicirati rasnu mržnju, uzrokujete uznemiravanje ili bilo koje neprijatnosti usled kršenja privatnosti, poverenja određenim diskriminacionim, pretećim ili izazivačkim komentarima\n" +
        "- Dajete netačne informacije koje se tiču ličnih podataka u vašem profilu\n" +
        "- Koristite tuđe lične podatke bez dozvole te osobe ili lažno predstavljate da istupate u ime trećeg lica, firme ili organizacije\n" +
        "- Bavite se bilo kojim nezakonitim ili protivpravnim ponašanjem\n" +
        "- Modifikujete, ometate, presrećete ili hakujete internet sajt ili aplikaciju u cilju njihovog onesposobljavanja\n" +
        "- Koristite specijalizovane programe radi preuzimanja sadržaja sa sajta ili aplikacije\n" +
        "Ako prekršite bilo koji od ovih Uslova mi Vas možemo sprečiti da koristite ovaj internet sajt na stalnom ili privremenom nivou. Mi možemo preduzeti i druge mere, uključujući ali ne ograničavajući se na to da Vas prijavimo nadležnim državnim organima, pošaljemo upozorenje ili podnesemo tužbu."
}

export default en;
