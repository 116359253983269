const termsSrText = `Pažnja: molimo vas da pažljivo pročitate uslove korišćenja pre upotrebe internet sajta. Korišćenjem našeg internet sajta potvrđujete da ste pročitali, razumeli i prihvatili ove uslove korišćenja (u daljem tekstu: Uslovi). Javno gradsko saobraćajno preduzeće „Novi Sad“, Novi Sad zadržava pravo da u bilo kom trenutku izmeni Uslove unošenjem izmena u ovaj tekst. Predlažemo da s vremena na vreme posetite ovu internet stranicu i proverite da li su se Uslovi možda promenili od vaše poslednje posete.
Autobuska stanica „Novi Sad“ posluje u sklopu Javnog gradskog saobraćajnog preduzeća „Novi Sad“, Novi Sad čiji je Osnivač Skupština Grada Novog Sada. Autobuska stanica „Novi Sad“ pruža stanične usluge prevoznicima i putnicima od decembra 1967. godine a danas svoju delatnost pružanja staničnih usluga vrši u skladu sa odredbama važećeg Zakona o prevozu putnika u drumskom saobraćaju i Opštim uslovima poslovanja autobuskih stanica. U vezi sa gore navedenim Ministarstvo infrastrukture, građevine i saobraćaja je Javnom gradskom saobraćajnom preduzeću „Novi Sad“, Novi Sad izdalo licencu za pružanje staničnih usluga. U skladu sa važećim Zakonom o prevozu putnika u drumskom saobraćaju i važećim Opštim uslovima poslovanja autobuskih stanica dužni smo da Vas obavestimo o sledećem:
- Da Autobuska stanica „Novi Sad“ vrši rezervaciju i prodaju karata u međumesnom i međunarodnom prevozu putnika u ime i za račun prevoznika. Vozna karta je dokaz da je zaključen Ugovor o prevozu između prevoznika i putnika;
- Putnik je dužan da poseduje dokument kojim se potvđuje odabrani popust (indeks za studente, zdravstvenu knjižicu za decu, ček od penzije…).
- Da delatnost pružanja staničnih usluga obuhvata: davanje obaveštenja koja se odnose na vreme polazaka i dolazaka autobusa, cenu prevoza, naziv prevoznika i sl, prodaju voznih karata i izdavanje drugih prevoznih isprava u vezi sa prevozom; ukrcavanje i iskrcavanje putnika; smeštaj i čuvanje prtljaga i prijem i otpremu autobusa;
- Da su korisnici staničnih usluga prevoznici i putnici;
- Da je pružalac usluga dužan da vozne karte i druge prevozne karte prodaje i izdaje u skladu sa registrovanim odnosno odobrenim redom vožnje i važećim cenovnikom.
Pružalac stničnih usluga je:
Javno gradsko saobraćajno preduzeće „Novi Sad“, Novi Sad
Futoški put broj 46
21137 Novi Sad
MB: 08041822
PIB: 100277615
Autobuska stanica „Novi Sad“ se nalazi na adresi Bul Jaše Tomića broj 6 u Novom Sadu.

Sadržaji objavljeni na internet sajtu vlasništvo su Javnog gradskog saobraćajnog preduzeća „Novi Sad“, Novi Sad i mogu se upotrebljavati samo u nekomercijalne svrhe, pri čemu se moraju poštovati i sva navedena upozorenja o autorskim pravima. Nije dozvoljeno menjati sadržaj ovog sajta niti aplikacije ni na koji način, niti ga reprodukovati ili javno prikazivati, izvoditi, distribuirati ili na drugi način koristiti u javne ili komercijalne svrhe. Ukoliko želite da koristite sadržaje objavljene na ovom internet sajtu ili aplikaciji u druge svrhe od ovde navedenih potrebno je da nas kontaktirate radi dobijanja dozvole.
Kada koristite naš internet sajt nije dozvoljeno da:
- Ometate ili onemogućavate bilo koje funkcije povezane sa sigurnošću na sajtu ili funkcije koje sprečavaju ili ograničavaju upotrebu ili kopiranje dostupnog sadržaja
- Objavljujete ili prenosite na internet sajt ili na aplikaciju svaki materijal koji je preteći, klevetnički, nepristojan, uvredljiv, pornografski ili zlostavljački. Takođe materijal kojim možete inicirati rasnu mržnju, uzrokujete uznemiravanje ili bilo koje neprijatnosti usled kršenja privatnosti, poverenja određenim diskriminacionim, pretećim ili izazivačkim komentarima
- Dajete netačne informacije koje se tiču ličnih podataka u vašem profilu
- Koristite tuđe lične podatke bez dozvole te osobe ili lažno predstavljate da istupate u ime trećeg lica, firme ili organizacije
- Bavite se bilo kojim nezakonitim ili protivpravnim ponašanjem
- Modifikujete, ometate, presrećete ili hakujete internet sajt ili aplikaciju u cilju njihovog onesposobljavanja
- Koristite specijalizovane programe radi preuzimanja sadržaja sa sajta ili aplikacije
Ako prekršite bilo koji od ovih Uslova mi Vas možemo sprečiti da koristite ovaj internet sajt na stalnom ili privremenom nivou. Mi možemo preduzeti i druge mere, uključujući ali ne ograničavajući se na to da Vas prijavimo nadležnim državnim organima, pošaljemo upozorenje ili podnesemo tužbu.`

export default termsSrText;
