import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { m } from 'framer-motion';

export default function Maintenance() {
    const logoAnimation = {
        initial: { scale: 0 },
        animate: { scale: 1, transition: { duration: 0.5 } },
        whileHover: { scale: 1.1 }
    };

    const textAnimation = {
        initial: { y: 20, opacity: 0 },
        animate: { y: 0, opacity: 1, transition: { duration: 0.5, delay: 0.5 } }
    };

    return (
        <Container disableGutters
                   sx={{
                       display: 'flex',
                       flex: 1,
                       minWidth: "100%",
                       justifyContent: 'center',
                       alignItems: 'center',
                       height: '100vh',
                       padding: 0,
                       margin: 0,
                       maxWidth: '100%'
                   }}>
            <Box sx={{
                padding: 0,
                margin: 0,
                backgroundImage: 'url("/assets/images/header.png")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <m.div
                    variants={logoAnimation}
                    initial="initial"
                    animate="animate"
                    whileHover="whileHover"
                    style={{ width: '91px', height: '37px', backgroundImage: 'url("/assets/images/logo.png")', backgroundSize: 'cover', backgroundPosition: 'center', display: 'flex' }}
                />
                <m.div
                    variants={textAnimation}
                    initial="initial"
                    animate="animate"
                >
                    <Typography variant="h5" sx={{ mt: 2, color: 'white' }}>BUSKA je u izradi</Typography>
                </m.div>
            </Box>
        </Container>
    );
}
