import Dexie from "dexie";
import {OFFLINE_DB_NAME, offline_tables} from "../constants/dexie";

export const offlineDB = new Dexie(OFFLINE_DB_NAME);

// line name visak zbog id
export const createDB = () => {
    offlineDB.version(1).stores({
        all_invoices: `
        invoiceNumber,
        issueDate,
        issuedFor,
        lineName,
        lineId,
        returnLineName,
        returnLineId,
        returnDepTripTime,
        returnTripTime,
        depStationName,
        arrStationName,
        depTripTime,
        tripTime,
        price,
        date,
        returnDate,
        passengersData,
        state,
        phone,
        email,
        bornCountry,
        documentNumber
        `,
    });
    offlineDB.open();
};

export async function insertInvoice(invoice){
    return offlineDB.open().then(async  db => {
        const table = db.table(offline_tables.all_invoices)
        if(table){
            let exists = await table.where("invoiceNumber").equals(invoice.invoiceNumber).first();
            if(!exists){
                return table.put(invoice);
            }
            return null;
        }
    })
}

export async function getInvoicesByEmail(email) {
    try {
        await offlineDB.open();
        const table = offlineDB.table(offline_tables.all_invoices);
        if (table) {
            return await table.where('email').equalsIgnoreCase(email).toArray();
        }
        return [];
    } catch (error) {
        console.error("Error fetching invoices by email:", error);
        throw error;
    }
}
