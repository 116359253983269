import React, { createContext, useContext, useEffect, useState } from "react";

const TimerContext = createContext();

export const useTimer = () => useContext(TimerContext);

const TimerProvider = ({ children }) => {
    const [timer, setTimer] = useState(600); // Initial time in seconds (10 minutes)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let interval;

        if (timer > 0 && !loading) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            // Handle timer reaching zero, e.g., trigger an action
        }

        return () => clearInterval(interval);
    }, [timer, loading]);

    const value = {
        timer,
        loading,
        setTimer,
        setLoading,
    };

    return (
        <TimerContext.Provider value={value}>
            {children}
        </TimerContext.Provider>
    );
};

export default TimerProvider;
