import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {getLineStationsByLineIdApi} from "../../api/be/lineStations";

export const fetchLineStationsByLineId = createAsyncThunk('fetchLineStationsByLineId', async (lineId) => {
    return getLineStationsByLineIdApi(lineId)
})

const initialState = {
    lineStations: [],
    loadingLineStations: false,
}

export const lineStationsSlice = createSlice({
    name: 'lineStations',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLineStationsByLineId.pending, (state) => {
                state.loadingLineStations = true;
            })
            .addCase(fetchLineStationsByLineId.fulfilled, (state, action) => {
                state.loadingLineStations = false;
                state.lineStations = action.payload.content;
            })
            .addCase(fetchLineStationsByLineId.rejected, (state) => {
                state.loadingLineStations = false;
            });
    },
});
export default lineStationsSlice.reducer;
