import axios from "axios";
import {AUTH_BUSSOFT} from "../auth/FirebaseContext";

export const customSpringAxios = axios.create({
    baseURL: "https://api-lw64c4rxja-lm.a.run.app/api/bussoft/dev",
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: false,
});

customSpringAxios.interceptors.request.use(
    async (config) => {
        if (AUTH_BUSSOFT.currentUser) {
            try {
                const token = await AUTH_BUSSOFT.currentUser.getIdToken(true);
                config.headers.Authorization = `Bearer ${token}`;
            } catch (error) {
                console.error("Problem fetching token: ", error);
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);


customSpringAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        // if (error.response.status === 401 && !originalRequest._retry) {
        // }
        return Promise.reject((error.response && error.response.data) || "Something went wrong");
    },
);
