import {useTranslation} from "react-i18next";
// components
//
import {allLanguages, defaultLang} from "./config-lang";
import {getSessionLanguage, setSessionLanguage} from "../helper/sessions";
import {useState} from "react";

// ----------------------------------------------------------------------

export default function useLocales() {
    const {i18n, t: translate} = useTranslation();
    const langStorage = getSessionLanguage() || "";
    const [currentLang, setCurrentLang] = useState(allLanguages.find((_lang) => _lang.value === langStorage) || defaultLang);

    const handleChangeLanguage = async (newLang) => {
        await i18n.changeLanguage(newLang);
        setSessionLanguage(newLang);
        setCurrentLang(allLanguages.find((_lang) => _lang.value === newLang) || defaultLang)
    };

    return {
        onChangeLang: handleChangeLanguage,
        translate: (text, options) => translate(text, options),
        currentLang,
        currency: currentLang?.currency,
        allLanguages
    };
}
