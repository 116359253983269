const termsRuText = `Внимание: пожалуйста, внимательно прочтите условия использования перед использованием сайта. Используя наш сайт, вы подтверждаете, что прочитали, поняли и приняли настоящие условия использования (далее: Условия). Компания общественного городского транспорта «Нови Сад», г. Нови Сад оставляет за собой право изменять Условия в любое время путем внесения изменений в данный текст. Мы рекомендуем вам время от времени посещать этот веб-сайт и проверять, не изменились ли Условия с момента вашего последнего посещения.
Автовокзал «Нови-Сад» работает в составе Общественного городского транспортного предприятия «Нови-Сад» г. Нови-Сад, основанного Собранием города Нови-Сад. Автовокзал «Нови Сад» оказывает станционные услуги перевозчикам и пассажирам с декабря 1967 года и сегодня осуществляет свою деятельность по оказанию станционных услуг в соответствии с положениями действующего Закона о перевозках пассажиров в дорожном движении и Общего Условия деятельности автовокзалов. В связи с вышеизложенным Министерство инфраструктуры, строительства и транспорта выдало лицензию на оказание станционных услуг компании общественного городского транспорта «Нови Сад», г. Нови Сад. В соответствии с действующим Законом о пассажирских дорожных перевозках и действующими Общими условиями автовокзалов мы обязаны сообщить вам следующее:
- Да, Автовокзал «Нови Сад» осуществляет бронирование и продажу билетов на междугородние и международные пассажирские перевозки от имени и по поручению перевозчика. Билет на поезд является доказательством заключения договора перевозки между перевозчиком и пассажиром;
- Пассажиру необходим документ, подтверждающий выбранную скидку (индекс для студентов, медицинская карта для детей, пенсионный чек...).
- Что деятельность по оказанию станционных услуг включает в себя: подачу извещений, связанных со временем отправления и прибытия автобусов, ценой перевозки, наименованием перевозчика и т.п., продажу железнодорожных билетов и выдачу других транспортных документов, связанных с к транспортировке; посадка и высадка пассажиров; размещение и хранение багажа, а также прием и отправка автобусов;
- что пользователями услуг станций являются перевозчики и пассажиры;
- Поставщик услуг обязан продавать и выдавать билеты на поезд и другие билеты на транспорт в соответствии с зарегистрированным или утвержденным расписанием движения и действующим прейскурантом.
Поставщик услуг:
омпания общественного городского транспорта «Нови Сад», г. Нови Сад
Футошки дорога № 46
21137 Нови Сад
МБ: 08041822
ПИБ: 100277615
Автобусная станция «Нови Сад» находится по адресу Буль Яше Томич № 6 в Нови-Саде.

Содержимое, опубликованное на сайте, является собственностью Общественного городского транспортного предприятия «Нови Сад», г. Нови Сад и может быть использовано только в некоммерческих целях, при этом необходимо соблюдать все предупреждения об авторских правах. Запрещается каким-либо образом изменять содержимое этого сайта или приложения, а также воспроизводить или публично демонстрировать, воспроизводить, распространять или иным образом использовать его в общественных или коммерческих целях. Если вы хотите использовать содержимое, опубликованное на этом веб-сайте или в приложении, для целей, отличных от перечисленных здесь, вам необходимо связаться с нами для получения разрешения.
При использовании нашего сайта Вам не разрешается:
- Вмешиваться или отключать любые функции Сайта, связанные с безопасностью, или функции, которые предотвращают или ограничивают использование или копирование доступного контента.
- Вы публикуете или загружаете на веб-сайт или в приложение любые материалы, которые являются угрожающими, клеветническими, непристойными, оскорбительными, порнографическими или оскорбительными. Также материалы, с помощью которых вы можете инициировать расовую ненависть, вызывать преследование или любые неудобства из-за нарушения конфиденциальности, доверять определенным дискриминационным, угрожающим или провокационным комментариям.
- Вы предоставляете неверную информацию относительно персональных данных в своем профиле
- Использовать чью-либо личную информацию без разрешения этого человека или ложно заявлять, что вы действуете от имени третьего лица, компании или организации.
- Заниматься любым незаконным или противоправным поведением.
- Вы изменяете, нарушаете, перехватываете или взламываете веб-сайт или приложение, чтобы отключить их.
- Используйте специализированные программы для скачивания контента с сайта или приложения
Если вы нарушите какое-либо из этих Условий, мы можем запретить вам использовать этот веб-сайт на постоянной или временной основе. Мы можем предпринять другие действия, включая, помимо прочего, сообщение о вас в соответствующие государственные органы, отправку предупреждения или подачу иска.`
export default termsRuText;
