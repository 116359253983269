import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import enLocales from "./langs/en";
import srLocales from "./langs/sr";
import cirLocales from "./langs/cir";
import ruLocales from "./langs/ru";
import huLocales from "./langs/hu";
import skLocales from "./langs/sk";
import {getSessionLanguage} from "../helper/sessions";


// ----------------------------------------------------------------------

let lng = "sr";

const storageAvailable = getSessionLanguage();

if (storageAvailable) {
  lng = storageAvailable;
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enLocales },
      sr: { translations: srLocales },
      cir: { translations: cirLocales },
      ru: { translations: ruLocales },
      hu: { translations: huLocales },
      sk: { translations: skLocales },
    },
    lng,
    fallbackLng: lng,
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    interpolation: {
      escapeValue: false
    }
  });

export const translate = (name, options) => {
  return i18n.t(name, options);
};

export default i18n;
