// ----------------------------------------------------------------------

export default function Snackbar(theme) {
    return {
        MuiSnackbar: {
            styleOverrides: {
                background: "red",
            }
        }
    };
}
