import {Navigate, useRoutes} from "react-router-dom";
import {PATH_AFTER_LOGIN} from "../config";
import {
    CartPage,
    HomePage,
    InvoicePreviewPage,
    ProfilePage,
    ResultPage,
    ArrivalsAndDepartures,
    RefundPolicy, OUPPage, PasswordReset, EmailVerification
} from "./elements";
import {PATH_DASHBOARD} from "./path";
import ErrorPage from "../pages/ErrorPage";
import Dashboard from "../layouts/dashboard";
import Maintenance from "../pages/Maintenance";
import TermsPage from "../pages/TermsPage";
import PrivacyPage from "../pages/PrivacyPage";

export default function Router() {
    return useRoutes([
        {path: PATH_DASHBOARD.general.privacy, element: <PrivacyPage/>},
        {path: PATH_DASHBOARD.general.terms, element: <TermsPage/>},
        {path: PATH_DASHBOARD.general.refund, element: <RefundPolicy/>},
        {path: PATH_DASHBOARD.general.oup, element: <OUPPage/>},
        {path: PATH_DASHBOARD.general.confirm, element: <EmailVerification/>},
        {path: PATH_DASHBOARD.general.resetPassword, element: <PasswordReset/>},
        {
            path: PATH_DASHBOARD.root,
            element: (
                <Maintenance/>
            ),
            children: [
                {element: <Navigate to={PATH_AFTER_LOGIN} replace/>, index: true},
                {path: PATH_DASHBOARD.general.app, element: <HomePage/>},
                {path: PATH_DASHBOARD.general.payment, element: <CartPage/>},
                {path: PATH_DASHBOARD.general.result, element: <ResultPage/>},
                {path: PATH_DASHBOARD.general.invoice, element: <InvoicePreviewPage/>},
                {path: PATH_DASHBOARD.general.error, element: <ErrorPage/>},
                {path: PATH_DASHBOARD.general.profile, element: <ProfilePage/>},
                {path: PATH_DASHBOARD.general.arrAndDep, element: <ArrivalsAndDepartures/>},
                {path: PATH_DASHBOARD.general.terms, element: <TermsPage/>},
                {path: PATH_DASHBOARD.general.privacy, element: <PrivacyPage/>}
            ]
        },
        {path: "/*", element: <Navigate to={PATH_AFTER_LOGIN} replace/>, index: true}
    ]);
}
