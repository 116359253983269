// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    general: {
        app: path(ROOTS_DASHBOARD, '/home'),
        payment: path(ROOTS_DASHBOARD, '/payment'),
        result: path(ROOTS_DASHBOARD, '/result'),
        settings: path(ROOTS_DASHBOARD, '/settings'),
        invoice: path(ROOTS_DASHBOARD, `/invoice`),
        error: path(ROOTS_DASHBOARD, `/error`),
        profile: path(ROOTS_DASHBOARD, '/profile'),
        arrAndDep: path(ROOTS_DASHBOARD, '/arrAndDep'),
        terms: path(ROOTS_DASHBOARD, '/terms-of-use'),
        privacy: path(ROOTS_DASHBOARD, '/privacy-policy'),
        refund: path(ROOTS_DASHBOARD, '/refund'),
        oup: path(ROOTS_DASHBOARD, '/oup'),
        confirm: path(ROOTS_DASHBOARD, '/confirm'),
        resetPassword: path(ROOTS_DASHBOARD, '/reset-password'),
    }
};

