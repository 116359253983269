const hu = {
    bannerTitle: "Fedezze fel a kedvező árú\n" +
        "autóbuszjegyeket\n" +
        "következő kalandjára",
    bannerSubTitle: "Egyszerűen hasonlítsa össze és foglalja le következő utazását",
    appName: "NS portál",
    signIn: "Bejelentkezés",
    signOut: "Kijelentkezés",
    home: "Kezdőlap",
    profile: "Profil",
    startStation: "Indulási állomás",
    endStation: "Érkezési állomás",
    startDate: "Indulás dátuma",
    endDate: "Visszatérés dátuma",
    passengers: "Utasok",
    search: "Keresés",
    optional: "Opcionális",
    mostFavDestinations: "Legnépszerűbb úti célok",
    startingFrom: "már",
    rsd: "Ft",
    downloadOurApp: "Töltse le az alkalmazásunkat",
    singUpForNotifications: "Iratkozzon fel értesítésekre",
    termsOfUse: "Használati feltételek",
    privatePolicy: "Adatvédelmi irányelvek",
    appDescription: "Egyszerű felhasználói felülettel, gyors keresésekkel és biztonságos fizetéssel, alkalmazásunk lehetővé teszi, hogy mindössze néhány lépésben foglalhass és vásárolhass buszjegyeket mobilkészüléked kényelméből. Az utazás soha nem volt könnyebb – fedezd fel az úti célokat, válassz dátumokat és teremts felejthetetlen élményeket intuitív alkalmazásunk segítségével.",
    yourEmailAddress: "Az Ön e-mail címe",
    passengersDetails: "Utasok részletei",
    seatIsNotSelected: "A hely nem lett kiválasztva",
    selectGender: "Nem",
    male: "Férfi",
    female: "Nő",
    other: "Egyéb",
    selectSeat: "Válasszon ülést",
    contactDetails: "Kapcsolatfelvételi adatok",
    countryFrom: "Születési ország",
    contactPhone: "Kapcsolatfelvételi telefonszám",
    email: "E-mail",
    checkNotification: "Értesítések fogadása az ajánlatokról",
    documents: "Dokumentumok",
    passport: "Útlevél",
    documentNumber: "Dokumentum száma",
    ticketReview: "Jegy áttekintése",
    refund: "Visszatérítés",
    noRefund: "Nincs visszatérítés",
    travelConditions: "Utazási feltételek",
    condition1: "A jegyet a buszra való felszálláskor kell bemutatni a sofőrnek",
    condition2: "A jegyet a fiókjában vagy e-mailben találhatja meg a vásárlás után",
    baggage: "Poggyász",
    reservationTime: "Foglalás érvényessége",
    tripDetail: "Utazás részletei",
    invoiceDetails: "Számla részletei",
    passenger: "Utas",
    basic: "Alap",
    vat: "ÁFA",
    discount: "Kedvezmény",
    discountType: "Kedvezmény típusa",
    numberDiscountType: "Kedvezményes dokumentum száma",
    total: "Összesen",
    name: "Név",
    surname: "Vezetéknév",
    phone: "Telefon",
    bDay: "Születési dátum",
    sex: "Nem",
    street: "Utca",
    zip: "Irányítószám",
    city: "Város",
    state: "Állam",
    update: "Frissítés",
    chooseRightTransport: "Válassza a megfelelő közlekedési eszközt",
    foundResults: "Találatok",
    cheapest: "Legolcsóbb",
    fastest: "Leggyorsabb",
    earliest: "Legkorábbi",
    filter: "Szűrő",
    numberOfFreeSeats: "Szabad helyek száma",
    numberOfStation: "Pályaudvar száma",
    ticketPrice: "JEGY ÁRA",
    advertisementTitle: "Reklám rész",
    adults: "Felnőttek",
    youth: "Fiatalok",
    seniors: "Idősek",
    subscribe: "Feliratkozás",
    searchResult: "Keresési eredmény",
    routeDetails: "Útvonal részletei",
    buyTicket: "Jegyvásárlás",
    stationsList: "Összes állomás megtekintése",
    successfulPurchase: "Sikeres vásárlás",
    busTicket: "Buszjegy",
    invNumber: "Számlaszám",
    line: "Vonal",
    departure: "Indulás",
    return: "Vissza",
    departureTime: "Indulási idő",
    returnTime: "Visszaérkezési idő",
    contact: "Kapcsolatfelvételi adatok",
    note: "Megjegyzés",
    downloadTicket: "Jegy letöltése",
    paidAmount: "Fizetett összeg",
    paymentMethod: "Fizetési mód",
    carrierInfo: "Szállító adatai",
    carrierName: "Szállító neve",
    contactNumber: "Kapcsolatfelvételi szám",
    goHome: "Ugrás a kezdőlapra",
    pay: "Fizetés",
    back: "Vissza",
    userProfile: "Felhasználói profil",
    personalInfo: "Személyes adatok",
    boughtTickets: "Megvásárolt jegyek",
    logout: "Kijelentkezés",
    updatePhoto: "Fotó frissítése",
    personalData: "Személyes adatok",
    passwordChange: "Jelszó megváltoztatása",
    oldPassword: "Régi jelszó",
    newPassword: "Új jelszó",
    welcomeBack: "Üdvözöljük újra!",
    loginGoogle: "Folytatás Google-lal",
    loginEmail: "Folytatás e-maillel",
    login: "Bejelentkezés",
    forgottenPassword: "Elfelejtett jelszó?",
    dontHaveAcc: "Nincs fiókja? ",
    haveAcc: "Már van fiókja? ",
    registration: "Regisztráció",
    password: "Jelszó",
    rePassword: "Jelszó újra",
    privilegeType: "Kedvezmény típusa",
    privilegeNumber: "Kedvezmény száma",
    or: "Vagy",
    createAccount: "Fiók létrehozása",
    receiveNotifications: "Értesítések fogadása akciókról",
    createAccountDescriptionOne: "Gyorsabb buszjegy vásárlás a következő utazásokhoz",
    createAccountDescriptionTwo: "A megvásárolt jegyek és a közelgő utazások történetének megjelenítése",
    termsAccept: "Fiók létrehozásával elfogadja a Feltételeket és Adatvédelmi Irányelveket, és \nhogy e-maileket kapjon, amelyekről bármikor leiratkozhat.",
    passwordResetTitle: "Jelszó visszaállítása",
    backToLogin: "Vissza a bejelentkezéshez",
    passwordResetText: "Adja meg az e-mail címét, amit a regisztrációnál használt, és \nküldünk egy linket a jelszó visszaállításához.",
    appSectionBulletOneTitle: "Könnyű foglalás, gyors utazás",
    appSectionBulletTwoTitle: "Személyre szabott keresések",
    appSectionBulletThreeTitle: "Mobil szabadság",
    appSectionBulletOneDesc: "Egy egyszerű felhasználói felülettel, gyors keresésekkel és biztonságos fizetéssel rendelkező alkalmazást kínálunk. Mindössze néhány lépésre van szükség a buszjegyek foglalásához és vásárlásához közvetlenül a mobilkészülékéről.",
    appSectionBulletTwoDesc: "Fedezze fel az érdeklődésre számot tartó úti célokat, válasszon a megfelelő dátumok közül, mindezt gyors keresésekkel, amelyekkel időt takaríthat meg és könnyedén tervezheti meg utazását.",
    appSectionBulletThreeDesc: "Foglaljon és vásároljon buszjegyeket mozgás közben, közvetlenül a mobilkészülékéről. Kényelmet és rugalmasságot biztosítunk, hogy bárhol és bármikor kezelhesse utazásait.",
    futureTrips: "Közelgő utazások",
    completedTrips: "Befejezett utazások",
    cancelledTrips: "Lemondott utazások",
    returnTicket: "Visszajáró",
    trips: "Utazások",
    save: "Mentés",
    noOptions: "Nincs találat",
    years: "év",
    error: "Szerver hiba",
    noResult: "Nincs eredmény",
    totalSelected: "Kiválasztott",
    endStationError: "Válassza ki a végső állomást",
    passengersAmountError: "Válasszon legalább egy utast",
    subscribeSuccess: "Sikeresen feliratkozott az értesítésekre.",
    emailError: "Adjon meg egy érvényes e-mail címet",
    invalidTicket: "Kedvezményes vonatjegyet vásárolt. A fuvarozási szolgáltatás igénybevételekor a vonatszemélyzet kérésére köteles olyan okmányt betekintésre átadni, amely alapján megállapítható, hogy él a választott kiváltsághoz való jogával.",
    serbia: "Szerbia",
    albania: "Albánia",
    bih: "Bosznia-Hercegovina",
    bulgaria: "Bulgária",
    montenegro: "Montenegró",
    greece: "Görögország",
    croatia: "Horvátország",
    northMacedonia: "Macedónia",
    romania: "Románia",
    slovenia: "Szlovénia",
    turkey: "Törökország",
    required: "Kötelező mező",
    invalidEmail: "Érvénytelen e-mail",
    passwordsMustMatch: "A jelszavaknak meg kell egyezniük",
    passwordRequirements: "A jelszónak tartalmaznia kell legalább egy nagybetűt, egy számot és egy speciális karaktert",
    send: "Küldés",
    badUsernameAndPassword: "Érvénytelen e-mail vagy jelszó!",
    emailAlreadyInUse: "Az e-mail cím már foglalt!",
    invNumb: "Számlaszám",
    startSt: "Kezdő állomás",
    withBaggage: "Poggyásszal",
    findCheap: "Találjon olcsó",
    busTicketsFor: "buszjegyeket a",
    yourNextTrip: "következő utazására",
    findCheapBusTicketsForYourFavoriteDestinations: "Találjon olcsó buszjegyeket a kedvenc úti céljaihoz",
    easyCompareAndReservation: "Könnyen hasonlítsa össze és foglalja le következő utazását",
    subscribeForNewsletter: "Iratkozzon fel most, hogy vonzó különleges ajánlatokat és információkat kapjon a legjobb túrákról!",
    successMailReset: "Sikeresen megváltoztatta a jelszavát!",
    emailErrorTitle: "Figyelmeztetés! Az e-mail cím kötelező",
    emailErrorDesc: "Kérjük, adja meg e-mail címét a számla és az elektronikus jegy megkapásához a sikeres vásárlás után.",
    invalidEmailErrorDesc: "Kérjük, adjon meg egy helyes e-mail címet a számla és az elektronikus jegy megkapásához a sikeres vásárlás után.",
    studentsDiscount: "Diákigazolvány",
    seniorsDiscount: "Nyugdíjas kártya",
    seatNumber: "Ülés száma",
    seatNumberNotDefined: "Az ülés nem számozott",
    emailHelper: "Az e-mail cím kötelező adat",
    stationInfo: "Állomás adatai",
    stationName: "Név",
    stationService: "Állomásszolgáltatás",
    profileIsUpdated: "Profil sikeresen frissítve",
    profileIsUpdatedError: "Hiba történt",
    directionType: "Irány típusa",
    oneWay: "Egyirányú",
    refundOnlyAtStation: "A pénzvisszatérítés a pultnál lehetséges",
    agreeForReturn: "Egyetértek a megadott utazási feltételekkel",
    tripType: "Jegy típusa",
    returnTicketType: "Retúrjegy",
    oneWayTicketType: "Egyirányú jegy",
    agreementErrorTitle: "Utazási feltételek!",
    agreementErrorDesc: "Meg kell erősítenie, hogy egyetért az utazási feltételekkel",
    returnTimePolicyDaysValidation: "A visszatérő jegy 90* napig érvényes",
    arrivalsDepartures: "Érkezések és indulások",
    depTime: "Indulás",
    depStation: "Indulási állomás",
    lineName: "Járat",
    expectedTime: "Várható érkezés",
    carrier: "Szállító",
    arrivals: "Érkezések",
    departures: "Indulások",
    arrived: "Megérkezett",
    notArrived: "Váratott",
    peron: "Peron",
    status: "Állapot",
    searchDepStation: "Indulási állomás",
    searchLineName: "Járat",
    searchCarrier: "Szállító",
    date: "Dátum",
    today: "Ma",
    yesterday: "Tegnap",
    tomorrow: "Holnap",
    isAgreedForReturnCondition: "A visszautazás előtt az AS pultjánál hitelesíteni kell a visszatérő jegyet",
    arrAndDep: "Érkezések / indulások",
    agreeForReturnLikeSo: "Egyetértek a megadott utazási feltételekkel, valamint a",
    termsOfUse2: "használati feltételekkel",
    privatePolicy2: "adatvédelmi irányelvekkel",
    and: "és",
    invalidPerson: "Kedvezmény fogyatékkal élőknek",
    hideDetails: "Részletek elrejtése",
    showAllStations: "Összes állomás megjelenítése",
    invalidCardNumber: "Érvénytelen kártyaszám",
    issuePlace: "Kiadási hely",
    documentErrorDesc: "Megerősíteni kell, hogy hozzájárul az okmányok ellenőrzéséhez a járműbe való belépéskor",
    documentErrorTitle: "Okmány szükséges!",

}

export default hu;
