import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';

// scroll bar
// import 'simplebar/src/simplebar.css';


import {HelmetProvider} from "react-helmet-async";
import {Provider as ReduxProvider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers";

// redux
import {persistor, store} from "./redux/store";
import {AuthProvider} from "./auth/FirebaseContext";

import {SettingsProvider} from "./components/settings";
import LoadingScreen from "./components/LoadingScreen";
import ScrollToTop from "./components/ScrollToTop";

import './index.css';
import App from './App';




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Suspense fallback={<LoadingScreen/>}>
        <AuthProvider>
            <HelmetProvider>
                <ReduxProvider store={store}>
                    <PersistGate loading={<LoadingScreen/>} persistor={persistor}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <SettingsProvider>
                                <BrowserRouter>
                                    <ScrollToTop/>
                                    <App/>
                                </BrowserRouter>
                            </SettingsProvider>
                        </LocalizationProvider>
                    </PersistGate>
                </ReduxProvider>
            </HelmetProvider>
        </AuthProvider>
    </Suspense>
);