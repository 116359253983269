import { lazy, Suspense } from "react";
import LoadingScreen from "../components/LoadingScreen";

const Loadable = (Component) => (props) =>
    <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
    </Suspense>;


// ----------------------------------------------------------------------

//PAGES
export const HomePage = Loadable(lazy(() => import("../pages/Home")));
export const CartPage = Loadable(lazy(() => import("../pages/CartPage")));
export const ResultPage = Loadable(lazy(() => import("../pages/Result")));
export const InvoicePreviewPage = Loadable(lazy(() => import("../pages/InvoicePreview")));
export const ErrorPage = Loadable(lazy(() => import("../pages/ErrorPage")));
export const ProfilePage = Loadable(lazy(() => import("../pages/Profile")));
export const ArrivalsAndDepartures = Loadable(lazy(() => import("../pages/ArrivalsAndDepartures")));

export const RefundPolicy = Loadable(lazy(() => import("../pages/RefundPage")));
export const OUPPage = Loadable(lazy(() => import("../pages/OUPPage")));
export const EmailVerification = Loadable(lazy(() => import("../pages/EmailVerification")));
export const PasswordReset = Loadable(lazy(() => import("../pages/PasswordReset")));
