import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getPaymentTransaction} from "../../helper/firebase";
import {PATH_DASHBOARD} from "../../routes/path";
import {useSnackbar} from "notistack";
import LoadingScreen from "../../components/LoadingScreen";

export default function ErrorPage() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!id) {
            enqueueSnackbar("Transakcija nije uspešna!", {variant: "error"});
            navigate("/home", {state: null})
        } else {
            setLoading(true);
            getPaymentTransaction(id).then(value => {
                if (value.result !== "CAPTURED" || !value.result) {
                    enqueueSnackbar("Transakcija nije uspešna!", {variant: "error"});
                    navigate(PATH_DASHBOARD.general.payment, {state: null});
                }
            }).catch(reason => {
                console.error("getPaymentTransaction", reason)
                enqueueSnackbar("Transakcija nije uspešna!", {variant: "error"});
                //todo pozovi API i proveri da li je uspesno
                navigate("/home", {state: null})
            }).finally(() => {
                setLoading(false)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    return (
        <>
            {loading && <LoadingScreen/>}
        </>
    );
}